import React, { useEffect, useState } from 'react';
import ScrollTopOnMount from "../../Layout/ScrollTopOnMount";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import { connect } from "react-redux";
import { getEducationalVideos, getFaqs } from "../../../actions/user_program";
import { Col, Container, Form, Row } from "react-bootstrap";
import FadeLoader from "react-spinners/FadeLoader";

function Faqs({ getFaqs, faqs }) {
    const [keyword, setKeyword] = useState(null)
    const [loader, setLoader] = useState(true)

    const [faqData, setFaqData] = useState(null)

    useEffect(() => {
        getFaqs(keyword)
        setLoader(false);
    }, [keyword]);

    useEffect(() => {
        if (faqs?.data) {
            setLoader(false)
        }
        setFaqData(faqs ? faqs.data : [])
        // setMusclesAnatomyData(array)
    }, [faqs]);


    const handleAccordionClick = (accordionTab, contents) => {
        accordionTab.classList.toggle('active');
        contents.style.display = contents.style.display === 'block' ? 'none' : 'block';

        const otherTabs = Array.from(accordionTab.parentNode.children).filter(
            (tab) => tab !== accordionTab
        );
        otherTabs.forEach((tab) => {
            tab.classList.remove('active');
            tab.querySelector('.contents').style.display = 'none';
        });
    };

    const handleSearchClick = () => {
        getFaqs(keyword)
        setLoader(true)
    };
    const handleSearch = (value) => {
        setKeyword(value)
        if (keyword && value.length == 0) {
            getFaqs(value)
        }
    };

    return (
        <>
            <ScrollTopOnMount />
            <Header />
            <section className="section accordion-section">
                <div className={"container h-100"}>

                    <Row className={"h-100"}>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                            <div className="videos_headings">
                                <h2 className="detail_span" style={{ color: "#fff" }}>FREQUENTLY ASKED QUESTIONS</h2>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                            <Form className="d-flex mt-3" onSubmit={(e) => e.preventDefault()}>
                                <Form.Control
                                    value={keyword}
                                    type="search"
                                    placeholder="Search"
                                    className="me-2 rounded-pill"
                                    aria-label="Search"
                                    onChange={(e) => {
                                        handleSearch(e.target.value)
                                        handleSearchClick(e.target.value)
                                    }
                                    }
                                />
                                {/* <Button className="rounded-pill" variant="outline-primary" onClick={handleSearchClick}>
                                    Search
                                </Button> */}
                            </Form>
                        </div>
                        {/*<Col className={"col-12 col-lg-12 col-md-12 m-auto"}>*/}
                        {/*    <div className="text-center">*/}
                        {/*        <h2>Frequently Asked Questions*/}
                        {/*        </h2>*/}
                        {/*    </div>*/}
                        {/*</Col>*/}
                    </Row>
                    <div className="faq-wrapper">
                        {/*<h3 className="sec-faq-heading">Frequently Asked Questions</h3>*/}
                        <div className="accordion">
                            {
                                loader ? <div className="col-12 col-sm-12 col-md-12  col-lg-12 col-xl-12 " >
                                    <div className={"empty-page"} >
                                        <div className="spin-loader">
                                            <FadeLoader color={"#EC1246"} height={10} />
                                        </div>
                                    </div>
                                </div> :
                                    <>
                                        {faqData?.length > 0 && faqData.map((data, key) => {
                                            if (data) {
                                                return (
                                                    <div className="accordion-tab" key={key} onClick={(e) => handleAccordionClick(e.currentTarget, e.currentTarget.querySelector('.contents'))}>
                                                        <div className="faq-heading">{data?.question}</div>
                                                        <div className="contents">
                                                            {data?.answer}
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })}
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

const mapStateToProps = state => {

    return {
        faqs: state.programReducer.faqs
    }
}
export default connect(mapStateToProps, { getFaqs })(Faqs)
