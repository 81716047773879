import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import logo from '../../images/logo.png'
import bg1 from '../../images/bg1.png'
import bg2 from '../../images/bannerimage2.png'
import bg3 from '../../images/truth.png'
import '../../../css/customnew.css';
import Slider from 'react-slick';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/core";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Container } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { useState } from 'react';

const Home1 = () => {
    const [animationComplete, setAnimationComplete] = useState(false);

    const handleSlideChange = () => {
        // You can add your animation logic here
        // For example, adding a class to trigger the animation
        const textPain = document.querySelector('.text-pain');
        if (textPain) {
            textPain.classList.add('animate-text');
        }
    };
    const handleAnimationComplete = () => {
        setAnimationComplete(true);
      };

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    const DummyData = [
        {
            id: 1,
            title: "Craving Something?",
            discription:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, seddo eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            imageSrc: '../images/bg1.png',
            alt: "banner-img",
            link: "#",
        },
        {
            id: 2,
            title: "Test 2",
            discription:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, seddo eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            imageSrc: '../images/register.png',
            alt: "banner-img",
            link: "#",
        },
        {
            id: 3,
            title: "Test 3",
            discription:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, seddo eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            imageSrc: '../images/register.png',
            alt: "banner-img",
            link: "#",
        }
    ];
    return (
        <>
            <section className={`banner-section scroll-animation`}>

            <section className="header-section">
                <Navbar bg="transparent" expand="lg" variant="dark">
                    <Navbar.Brand href="#home"><img src={logo} alt="logo" className="img-fluid" /></Navbar.Brand>
                    <Navbar.Toggle />
                    <Navbar.Collapse className="justify-content-end">
                        <Nav.Link href="#home">Home</Nav.Link>
                        <Nav.Link href="#link">About</Nav.Link>
                        <Nav.Link href="#link">Programs</Nav.Link>
                        <Nav.Link href="#link">Recommended Products</Nav.Link>
                        <NavDropdown title="SignIn" id="basic-nav-dropdown" className="rounded-button">
                            <NavDropdown.Item href="#action/3.1">Sign In</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.2">
                                Another action
                            </NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="#action/3.4">
                                Separated link
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Navbar.Collapse>
                </Navbar>
            </section>

            <div className={`banner-container scroll-animation`}>
                <Swiper
                    pagination={{ clickable: true }}
                    modules={[Pagination]}
                    spaceBetween={1}
                    slidesPerView={1}
                    className="mySwiper"
                    onSlideChange={handleSlideChange}
                    onTransitionEnd={handleAnimationComplete}
                // autoplay={{
                //     delay: 2500,
                //     disableOnInteraction: false,
                // }}
                >
                    {DummyData.map((item, index) => (
                        <SwiperSlide key={item.id}>
                            <div className="jt-banner-img">
                                <figure>
                                    <img src={bg2} alt={item.alt} />
                                </figure>
                                {index === 0 && (
                                    <div className="jt-banner-tital">
                                        <div className={`text-pain ${animationComplete ? 'active' : ''}`}>
                                            <h2>
                                                IS <span>PAIN</span> <br />
                                                HOLDING <br />
                                                YOU BACK?
                                            </h2>
                                        </div>
                                    </div>
                                )}

                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className={`home_000 ${animationComplete ? 'active scroll-animation' : ''}`}>
                    <div className="d-flex main-div">
                        <div className="row1 fade-out">
                            <h2 className="text-center">
                                We offer <span>PERSONALIZED</span> <br />
                                corrective-exercise programs <br />
                                based on your <span>ASSESSMENT</span> results
                            </h2>
                        </div>
                        <div class="card">
                            <div class="row2">
                                <div class="text-center1">
                                    <h2>TIRED OF HURTING?</h2>
                                    <h2> <span>KEEP GETTING INJURED?</span></h2>
                                </div>
                                <div class="text-center2 pt-4">
                                    <h2>MUSCLE IMBALANCES </h2>
                                    <h2> MAY BE TO BLAME</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>

            </section>

        </>
    )
}

export default Home1