import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Footer from "../../Layout/Footer";
import Header from "../../Layout/Header";
import ScrollTopOnMount from "../../Layout/ScrollTopOnMount";
import { getPainPoint } from "../../../actions/user_program";
import { Link, useLocation, useParams } from "react-router-dom";
import FadeLoader from "react-spinners/FadeLoader";
import { cross_origin } from "../../../utils/constants";

function TriggerBodyPart() {
  const [painPointData, setPainPointData] = useState(null);

  const [loader, setLoader] = useState(false);

  const { id } = useParams();
  let location = useLocation();

  useEffect(() => {
    console.log("id-->", id);
    console.log("pain_point_data-->", location?.state.pain_point_data);
    setPainPointData(
      location?.state.pain_point_data.painPointLibraries.length != 0
        ? location?.state.pain_point_data.painPointLibraries
        : []
    );
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const topElement = document.querySelector(".trigger-banner-recommended"); // Replace 'top-element' with the actual ID of your top element
      if (topElement) {
        topElement.scrollIntoView({
          behavior: "smooth",
          block: "start", // Scroll to the top of the element
        });
      }
    }, 2000);
  }, []);

  return (
    <>
      <ScrollTopOnMount />
      <Header />
      <div className={"clearfix"}></div>
      <section className="videos">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
              <div className="videos_headings">
                <h2 className="detail_span" style={{ color: "#fff" }}>
                  TRIGGER POINT LIBRARY
                </h2>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-4 mb-4">
              {/* <hr /> */}
              <div className="row">
                {loader ? (
                  <div className="col-12 col-sm-12 col-md-12  col-lg-12 col-xl-12 ">
                    <div className={"empty-page"}>
                      <div className="spin-loader">
                        <FadeLoader color={"#EC1246"} height={10} />
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {painPointData?.length > 0 ? (
                      painPointData.map((data, key) => {
                        if (data) {
                          return (
                            <div
                              className="col-6 col-sm-6 col-md-3  col-lg-2 col-xl-2 common-box-height"
                              key={key}
                            >
                              <Link
                                className={"nav-link library-nav"}
                                data-toggle="tab"
                                to={"/trigger-pain-point/detail/" + data?.id}
                                role="tab"
                              >
                                <div className="library-img-container">
                                  <div className="library-img-container-inner">
                                    <div className="title-one">
                                      {data?.title}
                                    </div>
                                    <div className="title-two">
                                      {data?.title2}
                                    </div>
                                    <div />
                                  </div>
                                  <div className="library-img-box">
                                    <img
                                      src={data?.image ? data?.image : ""}
                                      alt={data?.title ? data?.title : ""}
                                      crossOrigin={
                                        cross_origin ? "anonymous" : null
                                      }
                                    />
                                  </div>
                                </div>
                              </Link>
                            </div>
                          );
                        }
                      })
                    ) : (
                      <div className="col-12 col-sm-12 col-md-12  col-lg-12 col-xl-12 ">
                        <div className={"library-empty-page"}>
                          <span className="itemsPerPagelabel">Not Found</span>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            {/* {painPointData?.length > 0 && (
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
                <div className="pagination">
                  <span className="itemsPerPagelabel">Items Per Page:</span>
                  <div className={"perpagedropdown"}>
                    <select
                      id="itemsPerPage"
                      value={itemsPerPage}
                      onChange={handleItemsPerPageChange}
                    >
                      <option value={4}>4</option>
                      <option value={8}>8</option>
                      <option value={12}>12</option>
                      <option value={16}>16</option>
                      <option value={20}>20</option>
                    </select>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path d="M7 10l5 5 5-5z"></path>
                      <path d="M0 0h24v24H0z" fill="none"></path>
                    </svg>
                  </div>
                  <span className="totalpages">
                    {currentPage}-{totalPages} of {totalPages}
                  </span>
                  <div className="paginationbuttons">
                    {currentPage > 1 ? (
                      <button
                        className={"button-previous"}
                        onClick={() => {
                          setCurrentPage(currentPage - 1);
                          setLoader(true);
                        }}
                        disabled={currentPage === 1}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          role="presentation"
                        >
                          <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                          <path d="M0 0h24v24H0z" fill="none"></path>
                        </svg>
                      </button>
                    ) : (
                      ""
                    )}
                    {currentPage < totalPages ? (
                      <button
                        className={"button-next"}
                        onClick={() => {
                          setCurrentPage(currentPage + 1);
                          setLoader(true);
                        }}
                        disabled={currentPage === totalPages}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          role="presentation"
                        >
                          <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                          <path d="M0 0h24v24H0z" fill="none"></path>
                        </svg>
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            )} */}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default TriggerBodyPart;
