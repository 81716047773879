import React, { useState, useRef, useEffect } from "react";
import { BiPlay, BiVolumeFull, BiVolumeMute } from "react-icons/bi";
import { BiPause } from "react-icons/bi";
import { MdFullscreen, MdFullscreenExit } from "react-icons/md";
import { cross_origin } from "../../../utils/constants";
import HowToUseModal from "../../Layout/HowToUseModal";
import FullScreenModal from "./FullScreenModal";
import { connect } from "react-redux";
import { useSelector } from "react-redux";

const TestVideoPlayer = ({
  videoUrl,
  posterUrl,
  className,
  showExercise,
  autoPlay,
  height,
  width,
  playing,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [currentVideoTime, setCurrentVideoTime] = useState(0);
  const [durationVideo, setVideoDuration] = useState(0);
  const videoRef = useRef(null);
  const [repeatLoop, setRepeatLoop] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [volume, setVolume] = useState(1);
  const isMobile = window.innerWidth <= 767;
  const userAgent = window.navigator.userAgent;
  // const isiPhone = /iPhone/i.test(userAgent);
  useEffect(() => {
    // Add event listeners to track the current time and duration of the video.
    const videoElement = videoRef.current;
    const handleTimeUpdate = () =>
      setCurrentVideoTime(videoElement.currentTime);
    const handleLoadedMetadata = () => setVideoDuration(videoElement.duration);

    videoElement.addEventListener("timeupdate", handleTimeUpdate);
    videoElement.addEventListener("loadedmetadata", handleLoadedMetadata);

    // Remove event listeners when the component is unmounted.
    return () => {
      videoElement.removeEventListener("timeupdate", handleTimeUpdate);
      videoElement.removeEventListener("loadedmetadata", handleLoadedMetadata);
    };
  }, []);

  useEffect(() => {
    // handlePlayClick();
    console.log("UseEffect Playing");
    const handleFullScreenChange = () => {
      setIsFullScreen(
        document.fullscreenElement ||
          document.mozFullScreenElement ||
          document.webkitFullscreenElement ||
          document.msFullscreenElement ||
          document.webkitEnterFullscreen
      );
    };

    document.addEventListener("fullscreenchange", handleFullScreenChange);
    document.addEventListener("mozfullscreenchange", handleFullScreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullScreenChange);
    document.addEventListener("msfullscreenchange", handleFullScreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullScreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullScreenChange
      );
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullScreenChange
      );
      document.removeEventListener(
        "msfullscreenchange",
        handleFullScreenChange
      );
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullScreenChange
      );
    };
  }, []);

  useEffect(() => {
    if (playing) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  }, [playing]);

  const handlePlayClick = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
  };

  const handleTimeUpdate = () => {
    setCurrentTime(videoRef.current.currentTime);
    setDuration(videoRef.current.duration);
  };

  const handleProgressBarClick = (event) => {
    const progress = event.nativeEvent.offsetX / event.target.clientWidth;
    const currentTime = progress * duration;
    videoRef.current.currentTime = currentTime;
    setCurrentTime(currentTime);
  };
  const handleVideoEnded = () => {
    if (!repeatLoop) {
      // add the "flip-video" className to the video tag
      if (
        showExercise?.exerciseLevel?.video_flip == true ||
        showExercise?.position == 2
      ) {
        // videoRef.current.classList.remove("flip-video");
      } else {
        // videoRef.current.classList.add("flip-video");
      }
      setRepeatLoop(true);
    } else {
      if (
        showExercise?.exerciseLevel?.video_flip == true ||
        showExercise?.position == 2
      ) {
        // videoRef.current.classList.add("flip-video");
      } else {
        // videoRef.current.classList.remove("flip-video");
      }
      setRepeatLoop(false);
    }
  };

  const toggleFullScreen = () => {
    if (!isFullScreen) {
      console.log("fullscreen not true");
      if (videoRef.current.requestFullscreen) {
        videoRef.current.requestFullscreen();
      } else if (videoRef.current.mozRequestFullScreen) {
        videoRef.current.mozRequestFullScreen();
      } else if (videoRef.current.webkitRequestFullscreen) {
        console.log("here in webkit");
        videoRef.current.webkitRequestFullscreen();
      } else if (videoRef.current.msRequestFullscreen) {
        videoRef.current.msRequestFullscreen();
      } else {
        videoRef.current.webkitEnterFullscreen();
        console.log("here in else");
      }
    } else {
      console.log("fullscreen true");
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
    setIsFullScreen(false);
  };

  function formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");
    return `${formattedMinutes}:${formattedSeconds}`;
  }

  const toggleMute = () => {
    setIsMuted(!isMuted);
    videoRef.current.muted = !isMuted;
    if (isMuted) {
      videoRef.current.volume = 1;
    } else {
      videoRef.current.volume = 0;
    }
    setVolume(isMuted ? 1 : 0);
  };

  const handleVolumeChange = (event) => {
    const newVolume = parseFloat(event.target.value);
    setVolume(newVolume);
    if (newVolume > 0) {
      videoRef.current.muted = false;
    }
    videoRef.current.volume = newVolume;
    setIsMuted(newVolume === 0);
  };

  return (
    <>
      <div className="video-player">
        <video
          className={`class-hidden-controls ${className}`}
          ref={videoRef}
          src={videoUrl + "#t=0.001"}
          onClick={handlePlayClick}
          poster={posterUrl}
          onPlay={() => {
            setIsPlaying(true);
          }}
          onPause={() => {
            setIsPlaying(false);
          }}
          onTimeUpdate={handleTimeUpdate}
          width="100%"
          height="100%"
          onEnded={handleVideoEnded}
          crossOrigin={cross_origin ? "anonymous" : null}
          controls={false}
          controlsList="nodownload"
          playsInline={true}
          autoPlay={autoPlay ? autoPlay : false}
          onContextMenu={(e) => e.preventDefault()}
        />

        <i>
          {!isPlaying ? (
            <button className="detail-play-button" onClick={handlePlayClick}>
              <svg
                width="41"
                height="41"
                viewBox="0 0 41 41"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.8637 0.939392C9.87644 0.939392 0.977295 9.75378 0.977295 20.6364C0.977295 31.5189 9.87644 40.3333 20.8637 40.3333C31.8509 40.3333 40.75 31.5189 40.75 20.6364C40.75 9.75378 31.8509 0.939392 20.8637 0.939392ZM16.8864 29.5V11.7727L28.8182 20.6364L16.8864 29.5Z"
                  fill="#C00000"
                />
              </svg>
            </button>
          ) : (
            <></>
          )}
        </i>

        <div className="controls">
          <div className="detail-controls-wrap">
            <div className="left-wrapper">
              <button className="play-pause-button" onClick={handlePlayClick}>
                {isPlaying ? <BiPause /> : <BiPlay />}
              </button>
              <p className="video-duration">
                {formatTime(currentVideoTime)}/{formatTime(durationVideo)}
              </p>
            </div>

            <div className="progress-bar" onClick={handleProgressBarClick}>
              <div
                className="progress"
                style={{ width: `${(currentTime / duration) * 100}%` }}
              />
            </div>
            <div className="right-wrapper">
              <div className="sound-controls">
                <input
                  type="range"
                  min="0"
                  max="1"
                  step="0.01"
                  value={volume}
                  onChange={handleVolumeChange}
                  className="sound-bar"
                  style={{
                    background: `linear-gradient(to right, #ffffff ${
                      (volume / 1) * 100
                    }%, #ccc ${(volume / 1) * 100}%)`,
                  }}
                />
                <button onClick={toggleMute} className="sound-button">
                  {isMuted ? <BiVolumeMute /> : <BiVolumeFull />}
                </button>
              </div>
              <button className="fullscreen-button" onClick={toggleFullScreen}>
                {isFullScreen ? <MdFullscreenExit /> : <MdFullscreen />}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
// const mapStateToProps = (state) => ({
//   playBtn: state.instructionsReducer.playBtn,
// });

export default TestVideoPlayer;
// export default connect(mapStateToProps, {})(TestVideoPlayer);
