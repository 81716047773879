import React, { Navigate, useEffect } from "react";
import Footer from "../../Layout/Footer";
import Header from "../Home/Header";
import ScrollTopOnMount from "../../Layout/ScrollTopOnMount";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { getPageBySlug } from "../../../actions/auth";
import { Interweave } from "interweave";

const Page = ({ getPageBySlug, page }) => {
  let { slug } = useParams()
  useEffect(() => {
    getPageBySlug(slug)
  }, [slug])
  return (
    <>
      <ScrollTopOnMount />
      <Header />
      {/*<div className={"banner-recomended"}>*/}
      {/*  <Container className={"h-100"}>*/}
      {/*    <Row className={"h-100"}>*/}
      {/*      <Col className={"col-12 col-lg-6 col-md-6 m-auto"}>*/}
      {/*      </Col>*/}
      {/*    </Row>*/}
      {/*  </Container>*/}
      {/*</div>*/}
      <div className={"clearfix"}></div>
      <section className={"recomended-products"}>
        <Container>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className={"mx-auto"}>
              <div className={"mx-auto text-center page-title"}>
              <div class="videos_headings"><h2 style={{ color: "#fff" }} class="detail_span">{page ? page.name : ''}</h2></div>

                {/* <h2>
                  <b>{page ? page.name : ''}</b>
                </h2> */}

                <p><Interweave content={page ? page.content : 'No Data Available'} /></p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
};
const mapStateToProps = state => {
  return {
    page: state.auth.page
  }
}
export default connect(mapStateToProps, { getPageBySlug })(Page)
