import Carousel from "react-bootstrap/Carousel";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import {
  getExerciseDetail,
  getProgram,
  getWeekSchedule,
  updateProgramDayStatus,
} from "../../../actions/user_program";
import { FadeLoader } from "react-spinners";
import { useNavigate, useParams } from "react-router-dom";
import Exercises from "./Exercises";
import BreadcrumbMenu from "./BreadcrumbMenu";
import { UPDATE_PROGRAM_STATUS_PURGE } from "../../../actions/types";
import { RiMiniProgramLine } from "react-icons/ri";

const WeekSlider = ({
  weekScheduleRequest,
  weekScheduleSuccess,
  weekScheduleFailure,
  programId,
  getWeekSchedule,
  getProgram,
  weekSchedule,
  getExerciseDetail,
  exercises,
  updateProgramDayStatus,
  userProgramDayStatusUpdateSuccess,
  start,
  setStart,
}) => {
  const navigate = useNavigate();
  let { id } = useParams();
  const dispatch = useDispatch();
  const [activeDayId, setActiveDayId] = useState(null);
  const [activeDayName, setActiveDayName] = useState(null);
  const [resume, setResume] = useState(false);
  const [view, setView] = useState(false);

  useEffect(() => {
    getWeekSchedule(id);
  }, []);

  useEffect(() => {
    let activeEx = exercises?.filter((item, index) => item.is_started == true);
    setResume(activeEx?.length > 0 ? true : false);
  }, [exercises]);
  useEffect(() => {
    if (activeDayId != null && activeDayId != "" && activeDayId != undefined) {
      let days =
        weekSchedule && weekSchedule[0]["days"] ? weekSchedule[0]["days"] : [];
      localStorage.setItem("activeDayId", activeDayId);
      // localStorage.setItem('activeDayName', activeDayName)
      console.log(activeDayName, "activeDayName");
      let nextActiveDayIndex = days.findIndex(
        (item, index) => item.id === activeDayId
      );
      let nextActiveDay = days[nextActiveDayIndex + 1];
      if (nextActiveDay !== undefined && nextActiveDay !== null) {
        localStorage.setItem("nextActiveDay", nextActiveDay.id);
      }
      getExerciseDetail(activeDayId);
    }
  }, [activeDayId]);

  useEffect(() => {
    if (
      weekSchedule != null &&
      weekSchedule != "" &&
      weekSchedule != undefined
    ) {
      let days =
        weekSchedule && weekSchedule[0]["days"] ? weekSchedule[0]["days"] : [];
      let activeDay = days.filter((item, index) => item.is_active == 1);
      setActiveDayName(
        activeDay && activeDay[0] != null && activeDay[0] != undefined
          ? "Day " + activeDay[0]["programDay"]["day"]
          : weekSchedule[0]["days"]
          ? "Day " + weekSchedule[0]["days"][0]["programDay"]["day"]
          : ""
      );
      setActiveDayId(
        activeDay && activeDay[0] != null && activeDay[0] != undefined
          ? activeDay[0].id
          : weekSchedule && weekSchedule[0]["days"]
          ? weekSchedule[0]["days"][0].id
          : ""
      );
      localStorage.setItem("nextActiveDayId", activeDayId);
      console.log("active day");
    }
  }, [weekSchedule]);

  const changeDayId = (id, program_id, day) => {
    setActiveDayId(id);

    let dt = {
      is_active: 1,
      program_id: program_id,
    };
    updateProgramDayStatus(dt, id);
    localStorage.setItem("activeDayName", `Day ${day}`);
  };

  if (weekScheduleFailure) {
    navigate("/welcome", { replace: true });
  } else if (!weekScheduleSuccess) {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 center-loader">
            <FadeLoader color={"#FB1417"} height={10} />
          </div>
        </div>
      </div>
    );
  }

  if (userProgramDayStatusUpdateSuccess) {
    dispatch({ type: UPDATE_PROGRAM_STATUS_PURGE });
    getWeekSchedule(id);
  }

  return (
    <>
      <section className="assestmentgrid_001">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12 col-md-12 p-0">
              <Carousel
                className="box_week"
                indicators={false}
                nextLabel=""
                prevLabel=""
                interval={null}
              >
                {weekSchedule?.map((item, key) => (
                  <Carousel.Item
                    style={{ textAlign: "center" }}
                    key={item.week}
                  >
                    <h6>Week {item.week}</h6>
                    <div className="box_date">
                      <ul className="date_week">
                        {item.days.map((day) => (
                          <li key={day?.id}>
                            <p
                              className={
                                day?.is_active === 1 || activeDayId === day?.id
                                  ? "active"
                                  : ""
                              }
                              data-toggle="modal"
                              data-target="#statusCheckout1"
                              style={{ cursor: "pointer" }}
                            >
                              <span
                                onClick={() =>
                                  changeDayId(
                                    day?.id,
                                    day?.program_id,
                                    day?.programDay.day
                                  )
                                }
                              >
                                Day {day?.programDay.day}
                              </span>
                            </p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </section>
      <BreadcrumbMenu
        setView={setView}
        activeDayId={
          activeDayId ? activeDayId : localStorage.getItem("activeDayId")
        }
        lastActiveDayId={resume}
      />
      <Exercises
        activeDayId={
          activeDayId ? activeDayId : localStorage.getItem("activeDayId")
        }
        programId={id ? id : null}
        view={view}
        exercises={exercises}
        setStart={setStart}
        start={start}
      />
    </>
  );
};

WeekSlider.propTypes = {
  weekSchedule: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  weekScheduleRequest: state.programReducer.weekScheduleRequest || null,
  weekScheduleSuccess: state.programReducer.weekScheduleSuccess || null,
  weekScheduleFailure: state.programReducer.weekScheduleFailure || null,
  userProgramDayStatusUpdateSuccess:
    state.programReducer.userProgramDayStatusUpdateSuccess || null,
  programId: state.programReducer.programId,
  weekSchedule: state.programReducer.weekSchedule,
  exercises: state.programReducer.exercises,
});

export default connect(mapStateToProps, {
  getWeekSchedule,
  getProgram,
  getExerciseDetail,
  updateProgramDayStatus,
})(WeekSlider);

// export default WeekSlider
