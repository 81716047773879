import React, { useEffect, useState } from "react";
import ScrollTopOnMount from "../../Layout/ScrollTopOnMount";
import Header from "../Home/Header";
import Header2 from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import { Container, Row, Col, Form } from "react-bootstrap";
import imgNotFound from "../../images/broken-hd.png";
import imgRedirect from "../../images/redirect.png";
import amazonLogo1 from "../../images/brand-logo/amazon-logo.png";
import amazonLogo2 from "../../images/brand-logo/pngwing.png";
import amazonLogo3 from "../../images/brand-logo/target.png";
import amazonLogo4 from "../../images/brand-logo/rogue-fitness-logo.png";
import amazonLogo5 from "../../images/brand-logo/ebay_logo.png";
import amazonLogo6 from "../../images/brand-logo/ikea_logo.png";
import amazonLogo7 from "../../images/brand-logo/aliexpress_logo.png";
import amazonLogo8 from "../../images/brand-logo/bestbuy_logo.png";
import amazonLogo9 from "../../images/brand-logo/joom_logo.png";
import amazonLogo10 from "../../images/brand-logo/rakuten.png";
import {
  getByCategoryId,
  getRecommendedProducts,
} from "../../../actions/user_program";
import { connect } from "react-redux";
import { AiOutlineTwitter } from "react-icons/ai";
import { FaFacebookF } from "react-icons/fa";
import { FaTiktok, FaComment } from "react-icons/fa";
import profile from "../../images/Group 29358.png";
import { cross_origin, SOCIAL_MEDIA_LINKS } from "../../../utils/constants";
import FadeLoader from "react-spinners/FadeLoader";
import { Interweave } from "interweave";
import Modal from "react-bootstrap/Modal";
import { useLocation, useParams } from "react-router-dom";
import PropTypes from "prop-types";

const RecommendedProducts = ({
  getByCategoryId,
  recommandedProductCategories,
}) => {
  const [user, setUser] = useState(false);
  const [keyword, setKeyword] = useState(null);
  const [loader, setLoader] = useState(true);

  const [modalShow, setModalShow] = useState(false);
  const handleClose = () => setModalShow(false);
  const maxLength = 40;

  const [productDetails, setProductDetails] = useState([]);
  const [productCat, setProductCat] = useState([]);
  let location = useLocation();

  let { id } = useParams();

  useEffect(() => {
    console.log("id--->>>>", id);
  }, []);

  useEffect(() => {
    console.log("location?.state?.product", location?.state.products);

    getByCategoryId(location?.state.products);
  }, []);

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setUser(true);
    } else {
      setUser(false);
    }
  }, []);

  useEffect(() => {
    if (recommandedProductCategories) {
      setProductCat(recommandedProductCategories);
      setLoader(false);
    }
  }, [recommandedProductCategories]);

  const handleSearchClick = () => {
    setLoader(true);
    const filteredItems = productCat.filter((item) =>
      item?.title?.toLowerCase().includes(keyword?.toLowerCase())
    );
    if (filteredItems.length > 0) {
      setProductCat(filteredItems);
      setLoader(false);
    }
  };

  const handleSearch = (value) => {
    setKeyword(value);

    if (keyword && value.length == 0) {
      setProductCat(recommandedProductCategories);
    }
  };

  const handleReadMoreClick = (data) => {
    setProductDetails(data);
    setModalShow(true);
  };

  const displayLogo = (value) => {
    if (value == 1) {
      return amazonLogo1;
    } else if (value == 2) {
      return amazonLogo2;
    } else if (value == 3) {
      return amazonLogo3;
    } else if (value == 4) {
      return amazonLogo4;
    }
  };

  const DisplayLogo = ({ value, key, url }) => {
    const commonProps = {
      crossOrigin: cross_origin ? "anonymous" : null,
      alt: "Recommended Vendor",
      title: "Recommended Vendor",
      onError: ({ currentTarget }) => {
        currentTarget.onerror = null;
        currentTarget.src = imgRedirect; // Make sure imgRedirect is defined
      },
    };

    if (value === 1) {
      return (
        <a href={url} target="_blank" className={"btn-full"} key={key}>
          <img
            className={"img-fluid"}
            src={amazonLogo1} // Make sure displayLogo is defined
            {...commonProps}
          />
        </a>
      );
    } else if (value === 2) {
      return (
        <img
          className={"img-fluid"}
          src={amazonLogo2} // Make sure amazonLogo2 is defined
          {...commonProps}
        />
      );
    } else if (value === 3) {
      return (
        <img
          className={"img-fluid"}
          src={amazonLogo3} // Make sure amazonLogo3 is defined
          {...commonProps}
        />
      );
    } else if (value === 4) {
      return (
        <img
          className={"img-fluid"}
          src={amazonLogo4} // Make sure amazonLogo4 is defined
          {...commonProps}
        />
      );
    }

    // If value is none of the expected values, return null or a default component
    return null;
  };

  const ReadMoreP = ({ text, maxLength, className }) => {
    const [expanded, setExpanded] = useState(false);
    const truncatedText = text?.slice(0, maxLength);
    return (
      <div>
        <div
          className={`titles-label ${className}`}
          style={{ wordWrap: "break-word" }}
        >
          <Interweave content={expanded ? text : truncatedText} />
          <button
            className={"btn-expand float-right"}
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? "Read less" : "Read more"}
          </button>
        </div>
      </div>
    );
  };

  const ProductCard = (data) => {
    const productData = data?.data;
    const referral_link2 = productData?.referral_link2
      ? JSON.parse(productData?.referral_link2)
      : "";
    return (
      <div className={"product-box"}>
        <div className={"mx-auto text-center"}>
          <img
            className={"img-fluid product-img"}
            crossOrigin={cross_origin ? "anonymous" : null}
            src={productData?.image ? productData?.image : imgNotFound}
            alt={productData?.title}
            title={data?.title}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = imgNotFound;
            }}
          />
        </div>
        <div className={"mt-3 p-2"}>
          <div className={"product-info d-flex flex-column"}>
            <h4 className={"text-uppercase"}>{productData?.title}</h4>
            {productData?.description != "" ? (
              <>
                <div className={"product-desc"}>{productData?.description}</div>
                <div className={"float-right"}>
                  <button
                    className={"btn-expand-read float-right"}
                    onClick={() => handleReadMoreClick(productData)}
                  >
                    Read more
                  </button>
                </div>
              </>
            ) : (
              <div className={"comment-not-found"}>No description found.</div>
            )}
            {/* <p>
              <span>{productData?.description}</span>
            </p> */}
          </div>
          {/* <button
            className={"btn-expand-read float-right"}
            onClick={() => handleReadMoreClick(productData)}
          >
            Read more
          </button> */}
          <div className="clearfix mb-2">
            {productData?.note != null &&
            productData?.note != undefined &&
            productData?.note != "" &&
            productData?.note != " " ? (
              <div className="mt-4">
                <div
                  className={"product-comments"}
                  onClick={() => handleReadMoreClick(productData)}
                >
                  {productData?.note && <FaComment />}{" "}
                  <span>{productData?.note}</span>
                </div>
                <button
                  className={"btn-expand-read float-right"}
                  onClick={() => handleReadMoreClick(productData)}
                >
                  Read more
                </button>
              </div>
            ) : (
              <>
                <div className={"comment-not-found"}>No comment found.</div>
                {/* <button
                className={"btn-expand-read float-right"}
                onClick={() => handleReadMoreClick(productData)}
              >
                Read more
              </button> */}
              </>
            )}
          </div>
          {/* <p>
            <span>{productData?.note}</span>
          </p> */}

          <div className={"mx-auto text-center"}>
            <span>Buy it On</span>
            <div className="logo-grid">
              {referral_link2 &&
                referral_link2.map((link, key) => (
                  <>
                    {link?.selectValue && (
                      <a
                        href={link?.inputValue}
                        target="_blank"
                        className="logo-text"
                        key={key}
                      >
                        <img
                          className="logo-imgage"
                          src={
                            link?.selectValue == 1
                              ? link?.logo
                                ? link?.logo
                                : amazonLogo1
                              : link?.selectValue == 2
                              ? link?.logo
                                ? link?.logo
                                : amazonLogo2
                              : link?.selectValue == 3
                              ? link?.logo
                                ? link?.logo
                                : amazonLogo3
                              : link?.selectValue == 4
                              ? link?.logo
                                ? link?.logo
                                : amazonLogo4
                              : link?.selectValue == 5
                              ? link?.logo
                                ? link?.logo
                                : amazonLogo5
                              : link?.selectValue == 6
                              ? link?.logo
                                ? link?.logo
                                : amazonLogo6
                              : link?.selectValue == 7
                              ? link?.logo
                                ? link?.logo
                                : amazonLogo7
                              : link?.selectValue == 8
                              ? link?.logo
                                ? link?.logo
                                : amazonLogo8
                              : link?.selectValue == 9
                              ? link?.logo
                                ? link?.logo
                                : amazonLogo9
                              : link?.selectValue == 10
                              ? link?.logo
                                ? link?.logo
                                : amazonLogo10
                              : amazonLogo1
                          }
                        />
                      </a>
                    )}
                  </>
                ))}
            </div>
            {/* <a
              href={productData?.referral_link}
              target="_blank"
              className={"btn-full"}
            >
              {productData?.referral_link_image ? (
                <img
                  className={"img-fluid"}
                  crossOrigin={cross_origin ? "anonymous" : null}
                  src={productData?.referral_link_image}
                  alt={"Recommended Vendor"}
                  title={"Recommended Vendor"}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = imgRedirect;
                  }}
                />
              ) : (
                "Click Here"
              )}
            </a> */}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <ScrollTopOnMount />
      {user ? <Header2 /> : <Header />}
      <div className={"clearfix"}></div>

      <section className={"recomended-products"}>
        <Container>
          <Row>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
              <div className="videos_headings">
                <h2 className="detail_span" style={{ color: "#fff" }}>
                  Product Recommendations
                </h2>
                <p style={{ color: "#fff" }}>
                  Our favorite products used in the Body Solutions programs
                </p>
              </div>
            </div>
          </Row>
          <div className={"clearfix mt-5"}></div>
          <div className="col-12 col-sm-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
            <Form
              className="d-flex mt-3 mb-3"
              onSubmit={(e) => e.preventDefault()}
            >
              <Form.Control
                value={keyword}
                type="search"
                placeholder="Search"
                className="me-2 rounded-pill"
                aria-label="Search"
                onChange={(e) => {
                  handleSearch(e.target.value);
                  handleSearchClick(e.target.value);
                }}
              />
            </Form>
          </div>
          <Row>
            {loader ? (
              <div className="col-12 col-sm-12 col-md-12  col-lg-12 col-xl-12 ">
                <div className={"empty-page"}>
                  <div className="spin-loader">
                    <FadeLoader color={"#EC1246"} height={10} />
                  </div>
                </div>
              </div>
            ) : (
              <>
                {productCat.length > 0 ? (
                  productCat &&
                  productCat.map((products, key) => {
                    if (products) {
                      return (
                        <Col xs={12} sm={6} md={4} lg={3} xl={3} key={key}>
                          <ProductCard data={products} />
                        </Col>
                      );
                    }
                  })
                ) : (
                  <div className="text-center">
                    <h3>No products found.</h3>
                  </div>
                )}
              </>
            )}
          </Row>
          <Row className="mt-5 align-items-center">
            <Col md={6} className="px-0">
              <div className="kit">
                <h3>Share this kit:</h3>
                <ul className="links-icons">
                  <li>
                    <a href={SOCIAL_MEDIA_LINKS.TWITTER} target="_blank">
                      <AiOutlineTwitter />
                    </a>
                  </li>
                  <li>
                    <a href={SOCIAL_MEDIA_LINKS.FACEBOOK} target="_blank">
                      <FaFacebookF />
                    </a>
                  </li>
                  <li>
                    <a href={SOCIAL_MEDIA_LINKS.TIKTOK} target="_blank">
                      <FaTiktok />
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={6} className="px-0">
              <div className="social-profile">
                {/* <div className="wrapper">
                    <img src={profile} alt="profile" className="img-fluid" />
                  </div> */}
                <div className="content">
                  <figure>
                    <img src={profile} alt="profile" className="img-fluid" />
                  </figure>
                  <div className="wrapper">
                    <h3>@bodysolutions</h3>
                  </div>
                  <a
                    href={SOCIAL_MEDIA_LINKS.WEB}
                    target="_blank"
                    className="btn-product-follow"
                  >
                    Follow
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />

      {modalShow && (
        <>
          <Modal
            show={modalShow}
            onHide={handleClose}
            id="productDetails"
            className="modal fade"
            aria-hidden="true"
            aria-labelledby="exampleModalLabel"
            size="lg"
            centered
          >
            {/* <Modal.Header>
              <Modal.Title>{productDetails?.title}</Modal.Title>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </Modal.Header> */}
            <Modal.Body>
              {/* <div>
                <Row className={"mt-5"}>
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={8}
                    xl={8}
                    className={"mx-auto"}
                  >
                    
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={4}
                    xl={4}
                    className={"mx-auto"}
                  >
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={handleClose}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </Col>
                </Row>
              </div> */}
              <div className={"container"}>
                <Row>
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={4}
                    xl={4}
                    className={"mx-auto"}
                  >
                    <h4
                      className={
                        "text-uppercase modal-custom-title text-center mt-2 mb-2"
                      }
                    >
                      {productDetails?.title}
                    </h4>
                    <div className={"mx-auto text-center"}>
                      <img
                        className={"img-fluid product-img"}
                        crossOrigin={cross_origin ? "anonymous" : null}
                        src={
                          productDetails?.image
                            ? productDetails?.image
                            : imgNotFound
                        }
                        alt={productDetails?.title}
                        title={productDetails?.title}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = imgNotFound;
                        }}
                      />
                    </div>
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={8}
                    xl={8}
                    className={"mx-auto"}
                  >
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={handleClose}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>

                    <div className={"justify-text content-box"}>
                      <h6>Description:</h6>
                      {productDetails?.description ? (
                        <>
                          <div className={"modal-product-desc"}>
                            {productDetails?.description}
                          </div>
                        </>
                      ) : (
                        <div className={"comment-not-found"}>
                          No description found.
                        </div>
                      )}

                      <div className="mt-3">
                        <h6>Comments:</h6>
                        {productDetails?.note != null &&
                        productDetails?.note != undefined &&
                        productDetails?.note != "" &&
                        productDetails?.note != " " ? (
                          <>
                            <div className={"modal-product-comment"}>
                              {productDetails?.note}
                            </div>
                          </>
                        ) : (
                          <>
                            <div className={"comment-not-found"}>
                              No comment found.
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}
    </div>
  );
};
// RecommendedProducts.propTypes = {
//   products: PropTypes.object.isRequired,
// };
// const mapStateToProps = (state) => {
//   console.log(
//     "state--->>>",
//     state.programReducer.recommandedProductCategories.length
//   );

//   return {
//     recommandedProductCategories:
//       state.programReducer.recommandedProductCategories,
//   };
// };
// export default connect(mapStateToProps, {
//   getRecommendedProducts,
//   getByCategoryId,
// })(RecommendedProducts);

const mapStateToProps = (state) => {
  return {
    recommandedProductCategories:
      state.programReducer.recommandedProductCategories,
  };
};
export default connect(mapStateToProps, {
  getRecommendedProducts,
  getByCategoryId,
})(RecommendedProducts);

// export default RecommendedProducts;
