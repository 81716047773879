import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import barchart from "../../images/barchart.png";
import { Interweave } from "interweave";
import obliques from "../../images/obliques-m.PNG";
import ExerciseEducationalVideoModal from "../ExerciseEducationalVideoModal";
import { EXERCISE_TIMES } from "../../../utils/constants";
import { Button } from "react-bootstrap";
import { updateUserProgramDayExercise } from "../../../actions/user_program";
import { connect, useDispatch } from "react-redux";
import CustomVideoPlayer from "./CustomVideoPlayer";
import { getSkipReasons } from "../../../actions/user_test";
import SkipExerciseModal from "./SkipExerciseModal";
import StatusCheckoutModal from "../StatusCheckoutModal";
import Accordion from "react-bootstrap/Accordion";
import Dropdown from "react-bootstrap/Dropdown";
import FadeLoader from "react-spinners/FadeLoader";
import TestVideoPlayer from "./TestVideoPlayer";

const Test1Week1 = ({
  showExercise,
  activeDay,
  program,
  nextExercise,
  setActiveExercise,
  updateUserProgramDayExercise,
  exerciseUpdateSuccess,
  getSkipReasons,
  skipTestReason,
  skipExerciseSuccess,
  setRepeatCircuit,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [activeExerciseLevel, setActiveExerciseLevel] = useState(null);
  const [showEducationalModal, setShowEducationalModal] = useState(false);
  const [isCompleteDisable, setIsCompleteDisable] = useState(false);
  const [isStart, setIsStart] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [showExerciseSkipModal, setShowExerciseSkipModal] = useState(false);
  const [showStatusOutModal, setShowStatusOutModal] = useState(false);

  const [dumbbellsValue, setDumbbellsValue] = useState(null);
  const [timeValue, setTimeValue] = useState("");
  const [noteValue, setNoteValue] = useState("");
  const [rapsValue, setRapsValue] = useState(null);
  const [durationValue, setDurationValue] = useState("");
  const [startedValue, setStartedValue] = useState("");
  const [endedValue, setEndedValue] = useState("");

  const [seconds, setSeconds] = useState(0);
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [error, setError] = useState("");

  const [errorDumbell, setErrorDumbell] = useState("");
  const [errorNote, setErrorNote] = useState("");

  const [countTime, setCountTime] = useState(0);
  const countDownRef = useRef();
  const dispatch = useDispatch();

  const handleShow = () => setShowEducationalModal(true);
  const handleClose = () => setShowEducationalModal(false);
  const handleCloseExerciseSkipModal = () => setShowExerciseSkipModal(false);
  const handleStatusOutModal = () => setShowStatusOutModal(false);
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

  const [counter, setCounter] = useState(3);
  const [isCountTimerActive, setIsCountTimerActive] = useState(false);
  const [showCounter, setIsShowCounter] = useState(false);
  // const countDownRef = useRef();
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    // Update viewport width when the window is resized
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };
    // Attach the resize event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    console.log("showExercise--->", showExercise);
    if (showExercise) {
      console.log("if--->");
      setIsPaused(true);
      setIsTimerActive(false);
      setPlaying(false);
    }
  }, [showExercise]);

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo({
      top: 0, // 100 pixels from the top
      behavior: "smooth", // Smooth scroll behavior
    });
    // window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let interval = null;
    if (isTimerActive) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds + 1);
        setDurationValue(seconds + 1);
      }, 1000);
    } else if (!isTimerActive && seconds !== 0) {
      clearInterval(interval);
    }
    setEndedValue(new Date());
    return () => clearInterval(interval);
  }, [isTimerActive, seconds]);

  useEffect(() => {
    let interval = null;
    if (isCountTimerActive) {
      interval = setInterval(() => {
        setCounter(counter - 1);
        console.log("counter-->", counter);
        if (counter < 2) {
          setIsShowCounter(false);
          setSeconds(0);
          setStartedValue(new Date());
          setIsTimerActive(true);
          setIsStart(true);
          setIsCountTimerActive(false);
          setPlaying(true);
        }
      }, 1000);
    } else if (!isCountTimerActive && counter !== 0) {
      clearInterval(interval);
      setIsCountTimerActive(false);
      setIsShowCounter(false);
    }
    setEndedValue(new Date());
    return () => clearInterval(interval);
  }, [isCountTimerActive, counter]);

  const handleStart = () => {
    console.log("TAP-->");
    setCounter(3);
    setIsCountTimerActive(true);
    setIsShowCounter(true);
    // setSeconds(0);
    // setStartedValue(new Date());
    // setIsTimerActive(true);
    // setIsStart(true);
  };

  const handlePause = () => {
    setIsPaused(true);
    setIsTimerActive(false);
    setPlaying(false);
  };

  const handleResume = () => {
    setIsPaused(false);
    setIsTimerActive(true);
    setPlaying(true);
  };

  useEffect(() => {
    if (!skipTestReason) {
      getSkipReasons();
    }
  }, []);

  const handleClick = (event) => {
    // 👇️ toggle isActive state on click
    setIsActive((current) => !current);
  };

  const CheckPosImage = (feelPos) => {
    if (feelPos === "TR") {
      return "top-right";
    } else if (feelPos === "TL") {
      return "top-left";
    } else if (feelPos === "BL") {
      return "bottom-left";
    } else if (feelPos === "BR") {
      return "bottom-right";
    } else {
      return "";
    }
  };

  const CheckPosText = (overlayPos) => {
    if (overlayPos === "TR") {
      return "top-right";
    } else if (overlayPos === "TL") {
      return "top-left";
    } else if (overlayPos === "BL") {
      return "bottom-left";
    } else if (overlayPos === "BR") {
      return "bottom-right";
    } else {
      return "";
    }
  };

  const checkOrientationText = (n) => {
    if (n == 1) {
      return "Left Side Only";
    } else if (n == 2) {
      return "Right Side Only";
    }
    // else if (n == 3) {
    //     return "Both Sides";
    // }
    else {
      return "";
    }
  };

  const renderer = ({ minutes, seconds, completed, api }) => {
    if (completed) {
      return <h1>completed</h1>;
    } else {
      // api.start(true)
      return (
        <span>
          {minutes} minutes : {seconds} seconds
        </span>
      );
    }
  };

  useEffect(() => {
    if (showExercise) {
      setCountTime(showExercise.exerciseLevel.time * 60);
      setIsStart(true);
      setErrorDumbell("");
      setErrorNote("");
      setError("");
    }
  }, [showExercise]);

  useEffect(() => {
    if (!showStatusOutModal) {
      setIsStart(true);
    } else {
      setIsStart(false);
    }
  }, [showStatusOutModal]);
  const handleStartCountdown = (e) => {
    setStartedValue(new Date());
    setIsStart(true);
  };

  const handleCompleteExercise = () => {
    setIsStart(false);
    setShowExerciseSkipModal(false);
    setShowEducationalModal(false);
    setShowStatusOutModal(true);
  };

  const handleRepeatCircuit = () => {
    setRepeatCircuit(true);
    setSeconds(0);
    setIsTimerActive(false);
  };

  const setActiveExerciseFunc = (data) => {
    setActiveExercise(data);
  };

  const setDumbbellsValueFunc = (e) => {
    setDumbbellsValue(e.target.value);
    if (e.target.value.length > 5) {
      setErrorDumbell("Max length is 5");
      return;
    } else {
      setErrorDumbell("");
    }
  };

  const setTimeValueFunc = (e) => {
    // const timeFormatRegex = /^\d+min\s\d+sec$/;
    // if (!e.target.value || !timeFormatRegex.test(e.target.value)) {
    //   setError('Please enter the time in the format of "1min 30sec');
    //   return;
    // } else {
    //   setError('');
    //   setTimeValue(e.target.value)
    // }
    setTimeValue(e.target.value);
  };

  const setNoteValueFunc = (e) => {
    if (e.target.value.length > 500) {
      setErrorNote("Max length is 500");
      return;
    } else if (e.target.value.length == 0) {
      setNoteValue(e.target.value);
      if (rapsValue === null) {
        setIsCompleteDisable(false);
      }
    } else {
      setErrorNote("");
      setNoteValue(e.target.value);
      setIsCompleteDisable(true);
    }
  };

  const setRapsValueFunc = (e) => {
    const parsedRepsValue = parseInt(e.target.value, 10);
    if (e.target.value.length > 2) {
      setError("Max length is 2");
      return;
    } else if (isNaN(parsedRepsValue)) {
      setError("Please enter a valid number of reps");
      setRapsValue(null);
      if (noteValue?.length === 0) {
        setIsCompleteDisable(false);
      }
      return;
    } else if (!parsedRepsValue || !Number.isInteger(parsedRepsValue)) {
      setRapsValue(null);
      setError("Please enter a valid number of reps");
      if (noteValue?.length === 0) {
        setIsCompleteDisable(false);
      }
      return;
    } else {
      setError("");
      setRapsValue(e.target.value);
      setIsCompleteDisable(true);
    }
  };

  const handleFinish = async () => {
    setPlaying(false);
    setIsPaused(false);
    // const timeFormatRegex = /^\d+min\s\d+sec$/;
    if (error || errorDumbell || errorNote) {
      return;
    }
    let parsedRepsValue = parseInt(rapsValue, 10);
    if (
      showExercise.exerciseLevel.reps &&
      showExercise.exerciseLevel.metric == 10 &&
      (!parsedRepsValue ||
        !Number.isInteger(parsedRepsValue) ||
        parsedRepsValue == "")
    ) {
      setError("Please enter a valid number of reps");
      return;
    }

    if (
      showExercise.exerciseLevel.reps &&
      showExercise.exerciseLevel.metric == 20 &&
      !timeValue
    ) {
      setError("Please enter the time.");
      return;
    }

    if (
      showExercise.exerciseLevel.suggested_weight_lbs &&
      (dumbbellsValue == null || dumbbellsValue == "")
    ) {
      setErrorDumbell("Please enter the weight.");
      return;
    }

    let dt = {
      reps: rapsValue ? rapsValue : 0,
      user_program_day_exercise_id: showExercise.id,
      user_lbs: dumbbellsValue ? dumbbellsValue : 0,
      user_time: timeValue ? timeValue : "",
      start_at: startedValue ? startedValue : "",
      end_at: endedValue ? endedValue : "",
      note: noteValue ? noteValue : "",
      duration: durationValue ? durationValue : "",
      active_exercise_id: nextExercise ? nextExercise : showExercise.id,
      active_day_id: showExercise.user_program_day_id
        ? showExercise.user_program_day_id
        : "",
    };
    setIsTimerActive(false);
    setIsStart(false);
    setError("");
    setErrorDumbell("");
    setSeconds(0);
    setRapsValue(null);
    setDumbbellsValue(null);
    setIsCompleteDisable(false);
    updateUserProgramDayExercise(dt, showExercise.id);
    setCounter(3);
    setIsCountTimerActive(false);
    setIsShowCounter(false);
    window.scrollTo({
      top: 200, // 100 pixels from the top
      behavior: "smooth", // Smooth scroll behavior
    });
  };

  if (exerciseUpdateSuccess !== null && exerciseUpdateSuccess !== undefined) {
    dispatch({ type: "USER_PROGRAM_EXERCISE_UPDATE_PURGE" });
    if (nextExercise) {
      setActiveExerciseFunc(nextExercise);
    }
  }

  if (skipExerciseSuccess) {
    dispatch({ type: "USER_SKIP_EXERCISE_PURGE" });
    if (nextExercise) {
      setActiveExerciseFunc(nextExercise);
    } else {
      handleCompleteExercise();
    }
  }

  const handleActiveExercise = (exercise) => {
    setActiveExerciseLevel(exercise);
    setActiveExercise(exercise.id);
  };

  return (
    <>
      <section className="test1_001">
        <div className="container">
          {showExercise ? (
            <div className="row">
              <div className="col-12 col-lg-6 col-md-6  ">
                <div
                  className="ssa box_video"
                  style={{ textAlign: "center" }}
                  data-click="0"
                >
                  <TestVideoPlayer
                    playing={playing}
                    videoUrl={
                      showExercise.exerciseLevel.video +
                      "#t=" +
                      showExercise.exerciseLevel.video_start_time +
                      "," +
                      showExercise.exerciseLevel.video_end_time
                    }
                    posterUrl={showExercise.exerciseLevel.video_cover}
                    className={`${
                      showExercise.exerciseLevel.video_flip == true ||
                      showExercise.position == 2
                        ? "video flip-video"
                        : "video"
                    }`}
                    showExercise={showExercise}
                  />
                  {showCounter && <a className="timer">{counter}</a>}
                  {showExercise.feel_it_show == "1" ? (
                    <img
                      className={`img-fluid feel-it-here ${CheckPosImage(
                        showExercise.feel_it_position
                          ? showExercise.feel_it_position
                          : ""
                      )}`}
                      crossOrigin="anonymous"
                      src={
                        showExercise.exerciseLevel &&
                        showExercise.exerciseLevel.body_part
                          ? showExercise.exerciseLevel.gender == 20
                            ? showExercise.exerciseLevel.body_part.female_image
                            : showExercise.exerciseLevel.body_part.male_image
                          : obliques
                      }
                      alt={"Feel IT"}
                      title={"Feel It Here Image"}
                    />
                  ) : (
                    ""
                  )}
                  {showExercise.text_show == "1" ? (
                    <p
                      className={`text-placement ${CheckPosText(
                        showExercise.text_position
                      )}`}
                    >
                      {checkOrientationText(showExercise.position)}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                {!isStart ? (
                  <div className="timer_box">
                    <ul>
                      {showExercise.exerciseLevel.suggested_weight_lbs ? (
                        <li>
                          <svg
                            width="27"
                            height="15"
                            viewBox="0 0 27 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M26.0124 5.99222C25.9115 5.78411 25.8169 5.56338 25.5205 5.60753C25.3061 5.63906 25.1484 5.84717 25.1484 6.1688C25.1358 7.2346 25.1421 8.29409 25.1484 9.35988C25.1484 9.43556 25.161 9.50493 25.1799 9.5743C25.2367 9.75719 25.3439 9.90224 25.5457 9.92116C25.7412 9.94008 25.8673 9.82656 25.9493 9.6626C25.9682 9.61845 25.9935 9.5743 26.0124 9.53647C26.0124 8.35085 26.0124 7.17154 26.0124 5.99222Z"
                              fill="#9B9B9B"
                            />
                            <path
                              d="M21.4528 7.75174C21.4528 5.97331 21.4465 4.19489 21.4528 2.41646C21.4528 1.81734 21.2889 1.29391 20.8033 0.92813C20.0465 0.35424 19.0059 0.72632 18.6717 1.6786C18.5834 1.93086 18.5645 2.18942 18.5645 2.4543C18.5645 3.70928 18.5582 4.95797 18.5708 6.21296C18.5708 6.38954 18.5329 6.4463 18.3437 6.4463C14.9824 6.43999 11.6273 6.43999 8.26599 6.4463C8.06418 6.4463 8.04526 6.37693 8.04526 6.21296C8.05157 4.95166 8.05157 3.69037 8.05157 2.42907C8.05157 1.83626 7.8876 1.31282 7.402 0.934435C6.64522 0.354239 5.56681 0.745241 5.2641 1.70383C5.20104 1.89302 5.1632 2.08222 5.1632 2.28402C5.1632 5.94809 5.15689 9.60584 5.1695 13.2699C5.1695 13.9195 5.4533 14.4492 6.05241 14.7456C6.80288 15.1114 7.71102 14.651 7.96328 13.7933C8.03265 13.5537 8.05787 13.3141 8.05787 13.0681C8.05787 11.8131 8.05787 10.5644 8.05157 9.30944C8.05157 9.14547 8.0831 9.08871 8.25968 9.08871C11.6273 9.09502 14.995 9.09502 18.3627 9.08871C18.5392 9.08871 18.5771 9.14547 18.5771 9.30944C18.5771 10.6843 18.5645 12.0591 18.596 13.4402C18.6149 14.3231 19.3906 14.96 20.2231 14.8528C20.8348 14.7772 21.3898 14.1465 21.4402 13.4654C21.4528 13.3204 21.4528 13.169 21.4528 13.024C21.4528 11.2581 21.4528 9.50494 21.4528 7.75174Z"
                              fill="#9B9B9B"
                            />
                            <path
                              d="M4.5517 7.73914C4.5517 6.50938 4.5517 5.27962 4.5517 4.04985C4.5517 3.44443 4.28683 3.04712 3.79492 2.88946C2.95616 2.62459 2.23092 3.15433 2.23092 4.04354C2.22461 6.3391 2.23092 8.62836 2.23092 10.9239C2.23092 11.2077 2.20569 11.4978 2.25614 11.7816C2.35705 12.3555 2.82372 12.7023 3.47329 12.6771C4.04088 12.6582 4.50125 12.2609 4.52647 11.7122C4.55801 11.0185 4.54539 10.3185 4.5517 9.62478C4.55801 9.00044 4.5517 8.36979 4.5517 7.73914Z"
                              fill="#9B9B9B"
                            />
                            <path
                              d="M24.4613 7.75805C24.4613 6.50306 24.4613 5.25437 24.4613 3.99938C24.4613 3.50117 24.2595 3.11017 23.7928 2.92728C23.3387 2.7507 22.891 2.78223 22.5063 3.11648C22.2477 3.34351 22.1405 3.63992 22.1405 3.96785C22.1405 6.49044 22.1342 9.01304 22.1405 11.5356C22.1405 12.1347 22.5315 12.5888 23.0676 12.6645C23.7171 12.7591 24.2342 12.4627 24.4108 11.9014C24.4613 11.7437 24.4613 11.5798 24.4613 11.4158C24.4613 10.1987 24.4613 8.98151 24.4613 7.75805Z"
                              fill="#9B9B9B"
                            />
                            <path
                              d="M0.843262 7.75806C0.843262 8.25627 0.843262 8.75449 0.843262 9.259C0.843262 9.38513 0.843262 9.50496 0.893714 9.61847C0.969392 9.79506 1.08921 9.92749 1.29733 9.91488C1.50544 9.90227 1.61896 9.75721 1.67572 9.58063C1.70725 9.46712 1.71356 9.34099 1.71356 9.21486C1.71356 8.24366 1.71356 7.26616 1.71356 6.29496C1.71356 6.17514 1.70725 6.04901 1.67572 5.92918C1.61896 5.7463 1.50544 5.60755 1.29733 5.59494C1.08921 5.58233 0.969392 5.72107 0.893714 5.89765C0.849568 6.00486 0.843262 6.11838 0.843262 6.2382C0.843262 6.74902 0.843262 7.25354 0.843262 7.75806Z"
                              fill="#9B9B9B"
                            />
                          </svg>
                          <h6>Suggested Weight</h6>
                          <p>
                            {showExercise.exerciseLevel.suggested_weight_lbs
                              ? showExercise.exerciseLevel.suggested_weight_lbs
                              : ""}{" "}
                            (lbs)
                          </p>
                        </li>
                      ) : (
                        ""
                      )}
                      {showExercise.exerciseLevel.reps &&
                      showExercise.exerciseLevel.metric == 10 ? (
                        <li>
                          {/* <svg
                            width="27"
                            height="15"
                            viewBox="0 0 27 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M26.0124 5.99222C25.9115 5.78411 25.8169 5.56338 25.5205 5.60753C25.3061 5.63906 25.1484 5.84717 25.1484 6.1688C25.1358 7.2346 25.1421 8.29409 25.1484 9.35988C25.1484 9.43556 25.161 9.50493 25.1799 9.5743C25.2367 9.75719 25.3439 9.90224 25.5457 9.92116C25.7412 9.94008 25.8673 9.82656 25.9493 9.6626C25.9682 9.61845 25.9935 9.5743 26.0124 9.53647C26.0124 8.35085 26.0124 7.17154 26.0124 5.99222Z"
                              fill="#9B9B9B"
                            />
                            <path
                              d="M21.4528 7.75174C21.4528 5.97331 21.4465 4.19489 21.4528 2.41646C21.4528 1.81734 21.2889 1.29391 20.8033 0.92813C20.0465 0.35424 19.0059 0.72632 18.6717 1.6786C18.5834 1.93086 18.5645 2.18942 18.5645 2.4543C18.5645 3.70928 18.5582 4.95797 18.5708 6.21296C18.5708 6.38954 18.5329 6.4463 18.3437 6.4463C14.9824 6.43999 11.6273 6.43999 8.26599 6.4463C8.06418 6.4463 8.04526 6.37693 8.04526 6.21296C8.05157 4.95166 8.05157 3.69037 8.05157 2.42907C8.05157 1.83626 7.8876 1.31282 7.402 0.934435C6.64522 0.354239 5.56681 0.745241 5.2641 1.70383C5.20104 1.89302 5.1632 2.08222 5.1632 2.28402C5.1632 5.94809 5.15689 9.60584 5.1695 13.2699C5.1695 13.9195 5.4533 14.4492 6.05241 14.7456C6.80288 15.1114 7.71102 14.651 7.96328 13.7933C8.03265 13.5537 8.05787 13.3141 8.05787 13.0681C8.05787 11.8131 8.05787 10.5644 8.05157 9.30944C8.05157 9.14547 8.0831 9.08871 8.25968 9.08871C11.6273 9.09502 14.995 9.09502 18.3627 9.08871C18.5392 9.08871 18.5771 9.14547 18.5771 9.30944C18.5771 10.6843 18.5645 12.0591 18.596 13.4402C18.6149 14.3231 19.3906 14.96 20.2231 14.8528C20.8348 14.7772 21.3898 14.1465 21.4402 13.4654C21.4528 13.3204 21.4528 13.169 21.4528 13.024C21.4528 11.2581 21.4528 9.50494 21.4528 7.75174Z"
                              fill="#9B9B9B"
                            />
                            <path
                              d="M4.5517 7.73914C4.5517 6.50938 4.5517 5.27962 4.5517 4.04985C4.5517 3.44443 4.28683 3.04712 3.79492 2.88946C2.95616 2.62459 2.23092 3.15433 2.23092 4.04354C2.22461 6.3391 2.23092 8.62836 2.23092 10.9239C2.23092 11.2077 2.20569 11.4978 2.25614 11.7816C2.35705 12.3555 2.82372 12.7023 3.47329 12.6771C4.04088 12.6582 4.50125 12.2609 4.52647 11.7122C4.55801 11.0185 4.54539 10.3185 4.5517 9.62478C4.55801 9.00044 4.5517 8.36979 4.5517 7.73914Z"
                              fill="#9B9B9B"
                            />
                            <path
                              d="M24.4613 7.75805C24.4613 6.50306 24.4613 5.25437 24.4613 3.99938C24.4613 3.50117 24.2595 3.11017 23.7928 2.92728C23.3387 2.7507 22.891 2.78223 22.5063 3.11648C22.2477 3.34351 22.1405 3.63992 22.1405 3.96785C22.1405 6.49044 22.1342 9.01304 22.1405 11.5356C22.1405 12.1347 22.5315 12.5888 23.0676 12.6645C23.7171 12.7591 24.2342 12.4627 24.4108 11.9014C24.4613 11.7437 24.4613 11.5798 24.4613 11.4158C24.4613 10.1987 24.4613 8.98151 24.4613 7.75805Z"
                              fill="#9B9B9B"
                            />
                            <path
                              d="M0.843262 7.75806C0.843262 8.25627 0.843262 8.75449 0.843262 9.259C0.843262 9.38513 0.843262 9.50496 0.893714 9.61847C0.969392 9.79506 1.08921 9.92749 1.29733 9.91488C1.50544 9.90227 1.61896 9.75721 1.67572 9.58063C1.70725 9.46712 1.71356 9.34099 1.71356 9.21486C1.71356 8.24366 1.71356 7.26616 1.71356 6.29496C1.71356 6.17514 1.70725 6.04901 1.67572 5.92918C1.61896 5.7463 1.50544 5.60755 1.29733 5.59494C1.08921 5.58233 0.969392 5.72107 0.893714 5.89765C0.849568 6.00486 0.843262 6.11838 0.843262 6.2382C0.843262 6.74902 0.843262 7.25354 0.843262 7.75806Z"
                              fill="#9B9B9B"
                            />
                          </svg> */}
                          <h6>Reps</h6>
                          <p>
                            {showExercise.exerciseLevel.reps
                              ? showExercise.exerciseLevel.reps
                              : ""}
                          </p>
                        </li>
                      ) : (
                        ""
                      )}
                      {showExercise.exerciseLevel.time &&
                      showExercise.exerciseLevel.metric == 20 ? (
                        <li>
                          <svg
                            width="21"
                            height="21"
                            viewBox="0 0 21 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.0722 0.866455C4.54769 0.866455 0.0693359 5.34481 0.0693359 10.8693C0.0693359 16.3938 4.54769 20.8722 10.0722 20.8722C15.5967 20.8722 20.0751 16.3938 20.0751 10.8693C20.0751 5.34481 15.5967 0.866455 10.0722 0.866455ZM14.1383 14.1624C13.8858 14.4716 13.4529 14.5025 13.1076 14.2294C11.9635 13.3172 10.8298 12.405 9.68569 11.4929C9.46409 11.3177 9.35587 11.1012 9.36102 10.8126C9.36617 9.90047 9.36102 8.98315 9.36102 8.07099C9.36102 7.12275 9.36102 6.17967 9.36102 5.23143C9.36102 4.90161 9.58777 4.62848 9.88667 4.55633C10.201 4.48418 10.5205 4.61817 10.6752 4.89646C10.7525 5.0356 10.7628 5.19021 10.7628 5.34481C10.7628 7.01453 10.7628 8.6791 10.7576 10.3488C10.7576 10.5034 10.8091 10.591 10.9225 10.6838C11.9429 11.498 12.9633 12.3123 13.9837 13.1317C14.3444 13.41 14.4011 13.8377 14.1383 14.1624Z"
                              fill="#9B9B9B"
                            />
                          </svg>
                          <h6>Time</h6>
                          <p>
                            {showExercise.exerciseLevel.time
                              ? EXERCISE_TIMES[showExercise.exerciseLevel.time]
                              : ""}
                          </p>
                        </li>
                      ) : (
                        ""
                      )}
                      {showExercise.exerciseLevel.level ? (
                        <li>
                          <Dropdown className={"level-change-link"}>
                            <Dropdown.Toggle
                              variant="success"
                              id="dropdown-basic"
                            >
                              <svg
                                width="15"
                                height="25"
                                viewBox="0 0 15 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  x="0.853027"
                                  y="16.9424"
                                  width="2.45446"
                                  height="7.36337"
                                  fill="#9B9B9B"
                                />
                                <rect
                                  x="6.23291"
                                  y="8.96558"
                                  width="2.45446"
                                  height="15.3404"
                                  fill="#9B9B9B"
                                />
                                <rect
                                  x="11.6133"
                                  y="0.988525"
                                  width="2.45446"
                                  height="23.3173"
                                  fill="#9B9B9B"
                                />
                              </svg>
                              <h6>Level</h6>
                              <p
                                className={`level level${showExercise.exerciseLevel.level.id}`}
                              >
                                {" "}
                                {showExercise.exerciseLevel.level
                                  ? showExercise.exerciseLevel.level.name
                                  : ""}
                              </p>
                            </Dropdown.Toggle>

                            <Dropdown.Menu className={"level-change-link-menu"}>
                              <h4>Exercise Levels</h4>
                              <div id="wrapper1">
                                <img src={barchart} alt="barchart" />
                                <div className="btn_chart text-center">
                                  <Link
                                    to={`/program/change-level`}
                                    state={{
                                      exercise: showExercise,
                                      activeDayId: activeDay,
                                      programId: program.id,
                                      redirectLink: "/assessment-timer",
                                    }}
                                    className="theme_btn1 small_btn"
                                  >
                                    Change Level
                                  </Link>
                                </div>
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>{" "}
                        </li>
                      ) : (
                        ""
                      )}
                      {showExercise.exerciseLevel.exerciseEducationVideo
                        ?.length > 0 ? (
                        <li>
                          <Link
                            to="#"
                            className="edu popup-youtube-custom"
                            onClick={(e) => {
                              e.preventDefault();
                              handleShow();
                            }}
                          >
                            <svg
                              width="28"
                              height="24"
                              viewBox="0 0 26 22"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M25.7266 5.55698C25.5718 5.86026 25.3072 6.00867 24.991 6.11837C21.2355 7.44118 17.48 8.7769 13.7309 10.1126C13.4664 10.2094 13.2276 10.203 12.9566 10.1126C9.1624 8.75755 5.36172 7.41537 1.56104 6.0732C1.28357 5.97641 1.1029 5.79573 0.960938 5.56343C0.960938 5.42147 0.960938 5.27305 0.960938 5.13109C1.01256 4.87298 1.18678 4.71812 1.41908 4.61487C1.76753 4.46001 2.12889 4.35676 2.49024 4.22771C5.16814 3.2856 7.83958 2.3435 10.5175 1.40785C11.3176 1.13038 12.1113 0.846462 12.9114 0.562541C13.2083 0.459296 13.4922 0.459296 13.7826 0.562541C16.0346 1.34978 18.2801 2.14347 20.5322 2.93716C21.9905 3.45338 23.4488 3.96314 24.9071 4.47936C25.0233 4.51808 25.133 4.5568 25.2491 4.60842C25.5072 4.72457 25.7073 4.87944 25.7331 5.18271C25.7266 5.30532 25.7266 5.43437 25.7266 5.55698Z"
                                fill="#0F02FF"
                              />
                              <path
                                d="M6.05886 10.8741C6.05886 10.4095 6.05886 9.93841 6.05886 9.47381C6.05886 9.20279 6.05886 9.2028 6.31051 9.28668C8.36249 10.0158 10.4145 10.7386 12.46 11.4742C13.0601 11.6871 13.6344 11.6871 14.2281 11.4742C16.2736 10.7386 18.332 10.0158 20.3775 9.28023C20.5647 9.20925 20.6356 9.2157 20.6292 9.448C20.6163 10.3707 20.6163 11.2999 20.6292 12.2227C20.6356 12.7131 20.4485 13.0938 20.0807 13.4035C19.4871 13.9004 18.7837 14.1714 18.0545 14.3844C16.0284 14.9909 13.9635 15.1458 11.8599 14.978C10.4338 14.8619 9.04003 14.6038 7.71076 14.0553C7.29133 13.881 6.89772 13.6681 6.55572 13.3648C6.22018 13.068 6.0395 12.7002 6.0524 12.242C6.06531 11.7904 6.05886 11.3322 6.05886 10.8741Z"
                                fill="#0F02FF"
                              />
                              <path
                                d="M23.9849 8.00903C23.9849 9.20279 23.9849 10.3707 23.9849 11.5387C23.9849 12.3969 23.9914 13.2616 23.9785 14.1198C23.9785 14.2618 24.0172 14.3456 24.1333 14.436C24.9141 15.0425 24.9077 16.1653 24.114 16.7525C23.9978 16.8429 23.9785 16.9332 23.9785 17.0558C23.9785 18.1657 23.9849 19.2756 23.9785 20.3854C23.9785 20.7274 23.772 21.0049 23.4687 21.1017C23.1719 21.192 22.8557 21.0888 22.675 20.8436C22.5653 20.6952 22.5266 20.5274 22.5266 20.3467C22.5266 19.2497 22.5266 18.1592 22.5331 17.0623C22.5331 16.9203 22.5008 16.8364 22.3782 16.7461C21.6168 16.1718 21.6039 15.0425 22.3588 14.4618C22.5008 14.3521 22.5331 14.2489 22.5331 14.0811C22.5266 12.313 22.5331 10.545 22.5266 8.77046C22.5266 8.57042 22.5911 8.49944 22.7718 8.44137C23.1654 8.31231 23.5591 8.1639 23.9849 8.00903Z"
                                fill="#0F02FF"
                              />
                            </svg>
                            <h6>Educational</h6>
                            <h6>Video</h6>
                          </Link>
                        </li>
                      ) : (
                        ""
                      )}
                    </ul>
                  </div>
                ) : (
                  <div className="timer_box">
                    <ul className="border_1">
                      {showExercise.exerciseLevel.suggested_weight_lbs ? (
                        <li>
                          <svg
                            width="27"
                            height="15"
                            viewBox="0 0 27 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M26.0124 5.99222C25.9115 5.78411 25.8169 5.56338 25.5205 5.60753C25.3061 5.63906 25.1484 5.84717 25.1484 6.1688C25.1358 7.2346 25.1421 8.29409 25.1484 9.35988C25.1484 9.43556 25.161 9.50493 25.1799 9.5743C25.2367 9.75719 25.3439 9.90224 25.5457 9.92116C25.7412 9.94008 25.8673 9.82656 25.9493 9.6626C25.9682 9.61845 25.9935 9.5743 26.0124 9.53647C26.0124 8.35085 26.0124 7.17154 26.0124 5.99222Z"
                              fill="#9B9B9B"
                            />
                            <path
                              d="M21.4528 7.75174C21.4528 5.97331 21.4465 4.19489 21.4528 2.41646C21.4528 1.81734 21.2889 1.29391 20.8033 0.92813C20.0465 0.35424 19.0059 0.72632 18.6717 1.6786C18.5834 1.93086 18.5645 2.18942 18.5645 2.4543C18.5645 3.70928 18.5582 4.95797 18.5708 6.21296C18.5708 6.38954 18.5329 6.4463 18.3437 6.4463C14.9824 6.43999 11.6273 6.43999 8.26599 6.4463C8.06418 6.4463 8.04526 6.37693 8.04526 6.21296C8.05157 4.95166 8.05157 3.69037 8.05157 2.42907C8.05157 1.83626 7.8876 1.31282 7.402 0.934435C6.64522 0.354239 5.56681 0.745241 5.2641 1.70383C5.20104 1.89302 5.1632 2.08222 5.1632 2.28402C5.1632 5.94809 5.15689 9.60584 5.1695 13.2699C5.1695 13.9195 5.4533 14.4492 6.05241 14.7456C6.80288 15.1114 7.71102 14.651 7.96328 13.7933C8.03265 13.5537 8.05787 13.3141 8.05787 13.0681C8.05787 11.8131 8.05787 10.5644 8.05157 9.30944C8.05157 9.14547 8.0831 9.08871 8.25968 9.08871C11.6273 9.09502 14.995 9.09502 18.3627 9.08871C18.5392 9.08871 18.5771 9.14547 18.5771 9.30944C18.5771 10.6843 18.5645 12.0591 18.596 13.4402C18.6149 14.3231 19.3906 14.96 20.2231 14.8528C20.8348 14.7772 21.3898 14.1465 21.4402 13.4654C21.4528 13.3204 21.4528 13.169 21.4528 13.024C21.4528 11.2581 21.4528 9.50494 21.4528 7.75174Z"
                              fill="#9B9B9B"
                            />
                            <path
                              d="M4.5517 7.73914C4.5517 6.50938 4.5517 5.27962 4.5517 4.04985C4.5517 3.44443 4.28683 3.04712 3.79492 2.88946C2.95616 2.62459 2.23092 3.15433 2.23092 4.04354C2.22461 6.3391 2.23092 8.62836 2.23092 10.9239C2.23092 11.2077 2.20569 11.4978 2.25614 11.7816C2.35705 12.3555 2.82372 12.7023 3.47329 12.6771C4.04088 12.6582 4.50125 12.2609 4.52647 11.7122C4.55801 11.0185 4.54539 10.3185 4.5517 9.62478C4.55801 9.00044 4.5517 8.36979 4.5517 7.73914Z"
                              fill="#9B9B9B"
                            />
                            <path
                              d="M24.4613 7.75805C24.4613 6.50306 24.4613 5.25437 24.4613 3.99938C24.4613 3.50117 24.2595 3.11017 23.7928 2.92728C23.3387 2.7507 22.891 2.78223 22.5063 3.11648C22.2477 3.34351 22.1405 3.63992 22.1405 3.96785C22.1405 6.49044 22.1342 9.01304 22.1405 11.5356C22.1405 12.1347 22.5315 12.5888 23.0676 12.6645C23.7171 12.7591 24.2342 12.4627 24.4108 11.9014C24.4613 11.7437 24.4613 11.5798 24.4613 11.4158C24.4613 10.1987 24.4613 8.98151 24.4613 7.75805Z"
                              fill="#9B9B9B"
                            />
                            <path
                              d="M0.843262 7.75806C0.843262 8.25627 0.843262 8.75449 0.843262 9.259C0.843262 9.38513 0.843262 9.50496 0.893714 9.61847C0.969392 9.79506 1.08921 9.92749 1.29733 9.91488C1.50544 9.90227 1.61896 9.75721 1.67572 9.58063C1.70725 9.46712 1.71356 9.34099 1.71356 9.21486C1.71356 8.24366 1.71356 7.26616 1.71356 6.29496C1.71356 6.17514 1.70725 6.04901 1.67572 5.92918C1.61896 5.7463 1.50544 5.60755 1.29733 5.59494C1.08921 5.58233 0.969392 5.72107 0.893714 5.89765C0.849568 6.00486 0.843262 6.11838 0.843262 6.2382C0.843262 6.74902 0.843262 7.25354 0.843262 7.75806Z"
                              fill="#9B9B9B"
                            />
                          </svg>
                          <h6>Actual Weight</h6>
                          <div className="form-group  mb-0">
                            <input
                              id={"dumbbells"}
                              type="number"
                              placeholder="10-15lbs"
                              className="form-control form-2"
                              onChange={(e) => setDumbbellsValueFunc(e)}
                              step={2}
                              min={0}
                              max={1000}
                            />
                          </div>
                          <p>Enter your lbs</p>
                          {errorDumbell && (
                            <span className="show-error">{errorDumbell}</span>
                          )}
                        </li>
                      ) : (
                        ""
                      )}
                      {showExercise.exerciseLevel.reps &&
                      showExercise.exerciseLevel.metric == 10 ? (
                        <li>
                          {/*<svg*/}
                          {/*  width="27"*/}
                          {/*  height="15"*/}
                          {/*  viewBox="0 0 27 15"*/}
                          {/*  fill="none"*/}
                          {/*  xmlns="http://www.w3.org/2000/svg"*/}
                          {/*>*/}
                          {/*  <path*/}
                          {/*    d="M26.0124 5.99222C25.9115 5.78411 25.8169 5.56338 25.5205 5.60753C25.3061 5.63906 25.1484 5.84717 25.1484 6.1688C25.1358 7.2346 25.1421 8.29409 25.1484 9.35988C25.1484 9.43556 25.161 9.50493 25.1799 9.5743C25.2367 9.75719 25.3439 9.90224 25.5457 9.92116C25.7412 9.94008 25.8673 9.82656 25.9493 9.6626C25.9682 9.61845 25.9935 9.5743 26.0124 9.53647C26.0124 8.35085 26.0124 7.17154 26.0124 5.99222Z"*/}
                          {/*    fill="#9B9B9B"*/}
                          {/*  />*/}
                          {/*  <path*/}
                          {/*    d="M21.4528 7.75174C21.4528 5.97331 21.4465 4.19489 21.4528 2.41646C21.4528 1.81734 21.2889 1.29391 20.8033 0.92813C20.0465 0.35424 19.0059 0.72632 18.6717 1.6786C18.5834 1.93086 18.5645 2.18942 18.5645 2.4543C18.5645 3.70928 18.5582 4.95797 18.5708 6.21296C18.5708 6.38954 18.5329 6.4463 18.3437 6.4463C14.9824 6.43999 11.6273 6.43999 8.26599 6.4463C8.06418 6.4463 8.04526 6.37693 8.04526 6.21296C8.05157 4.95166 8.05157 3.69037 8.05157 2.42907C8.05157 1.83626 7.8876 1.31282 7.402 0.934435C6.64522 0.354239 5.56681 0.745241 5.2641 1.70383C5.20104 1.89302 5.1632 2.08222 5.1632 2.28402C5.1632 5.94809 5.15689 9.60584 5.1695 13.2699C5.1695 13.9195 5.4533 14.4492 6.05241 14.7456C6.80288 15.1114 7.71102 14.651 7.96328 13.7933C8.03265 13.5537 8.05787 13.3141 8.05787 13.0681C8.05787 11.8131 8.05787 10.5644 8.05157 9.30944C8.05157 9.14547 8.0831 9.08871 8.25968 9.08871C11.6273 9.09502 14.995 9.09502 18.3627 9.08871C18.5392 9.08871 18.5771 9.14547 18.5771 9.30944C18.5771 10.6843 18.5645 12.0591 18.596 13.4402C18.6149 14.3231 19.3906 14.96 20.2231 14.8528C20.8348 14.7772 21.3898 14.1465 21.4402 13.4654C21.4528 13.3204 21.4528 13.169 21.4528 13.024C21.4528 11.2581 21.4528 9.50494 21.4528 7.75174Z"*/}
                          {/*    fill="#9B9B9B"*/}
                          {/*  />*/}
                          {/*  <path*/}
                          {/*    d="M4.5517 7.73914C4.5517 6.50938 4.5517 5.27962 4.5517 4.04985C4.5517 3.44443 4.28683 3.04712 3.79492 2.88946C2.95616 2.62459 2.23092 3.15433 2.23092 4.04354C2.22461 6.3391 2.23092 8.62836 2.23092 10.9239C2.23092 11.2077 2.20569 11.4978 2.25614 11.7816C2.35705 12.3555 2.82372 12.7023 3.47329 12.6771C4.04088 12.6582 4.50125 12.2609 4.52647 11.7122C4.55801 11.0185 4.54539 10.3185 4.5517 9.62478C4.55801 9.00044 4.5517 8.36979 4.5517 7.73914Z"*/}
                          {/*    fill="#9B9B9B"*/}
                          {/*  />*/}
                          {/*  <path*/}
                          {/*    d="M24.4613 7.75805C24.4613 6.50306 24.4613 5.25437 24.4613 3.99938C24.4613 3.50117 24.2595 3.11017 23.7928 2.92728C23.3387 2.7507 22.891 2.78223 22.5063 3.11648C22.2477 3.34351 22.1405 3.63992 22.1405 3.96785C22.1405 6.49044 22.1342 9.01304 22.1405 11.5356C22.1405 12.1347 22.5315 12.5888 23.0676 12.6645C23.7171 12.7591 24.2342 12.4627 24.4108 11.9014C24.4613 11.7437 24.4613 11.5798 24.4613 11.4158C24.4613 10.1987 24.4613 8.98151 24.4613 7.75805Z"*/}
                          {/*    fill="#9B9B9B"*/}
                          {/*  />*/}
                          {/*  <path*/}
                          {/*    d="M0.843262 7.75806C0.843262 8.25627 0.843262 8.75449 0.843262 9.259C0.843262 9.38513 0.843262 9.50496 0.893714 9.61847C0.969392 9.79506 1.08921 9.92749 1.29733 9.91488C1.50544 9.90227 1.61896 9.75721 1.67572 9.58063C1.70725 9.46712 1.71356 9.34099 1.71356 9.21486C1.71356 8.24366 1.71356 7.26616 1.71356 6.29496C1.71356 6.17514 1.70725 6.04901 1.67572 5.92918C1.61896 5.7463 1.50544 5.60755 1.29733 5.59494C1.08921 5.58233 0.969392 5.72107 0.893714 5.89765C0.849568 6.00486 0.843262 6.11838 0.843262 6.2382C0.843262 6.74902 0.843262 7.25354 0.843262 7.75806Z"*/}
                          {/*    fill="#9B9B9B"*/}
                          {/*  />*/}
                          {/*</svg>*/}
                          <h6>Actual Reps</h6>
                          <div className="form-group  mb-0">
                            <input
                              id={"raps"}
                              type="number"
                              placeholder="10"
                              className="form-control form-2"
                              onChange={(e) => setRapsValueFunc(e)}
                              min={0}
                              max={1000}
                            />
                          </div>
                          <p>Enter your reps</p>
                          {error && <span className="show-error">{error}</span>}
                        </li>
                      ) : (
                        <li>
                          <svg
                            width="21"
                            height="21"
                            viewBox="0 0 21 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.0722 0.866455C4.54769 0.866455 0.0693359 5.34481 0.0693359 10.8693C0.0693359 16.3938 4.54769 20.8722 10.0722 20.8722C15.5967 20.8722 20.0751 16.3938 20.0751 10.8693C20.0751 5.34481 15.5967 0.866455 10.0722 0.866455ZM14.1383 14.1624C13.8858 14.4716 13.4529 14.5025 13.1076 14.2294C11.9635 13.3172 10.8298 12.405 9.68569 11.4929C9.46409 11.3177 9.35587 11.1012 9.36102 10.8126C9.36617 9.90047 9.36102 8.98315 9.36102 8.07099C9.36102 7.12275 9.36102 6.17967 9.36102 5.23143C9.36102 4.90161 9.58777 4.62848 9.88667 4.55633C10.201 4.48418 10.5205 4.61817 10.6752 4.89646C10.7525 5.0356 10.7628 5.19021 10.7628 5.34481C10.7628 7.01453 10.7628 8.6791 10.7576 10.3488C10.7576 10.5034 10.8091 10.591 10.9225 10.6838C11.9429 11.498 12.9633 12.3123 13.9837 13.1317C14.3444 13.41 14.4011 13.8377 14.1383 14.1624Z"
                              fill="#9B9B9B"
                            />
                          </svg>
                          <h6>Actual Time</h6>
                          <div className="form-group mb-0">
                            <input
                              id={"time"}
                              type="text"
                              placeholder="1min 20sec"
                              className="form-control form-2"
                              onChange={(e) => setTimeValueFunc(e)}
                              value={`${String(
                                Math.floor(seconds / 60)
                              ).padStart(2, "0")}:${String(
                                seconds % 60
                              ).padStart(2, "0")}`}
                            />
                          </div>
                          {/* <p>Enter your time</p> */}
                          {error && <span className="show-error">{error}</span>}
                        </li>
                      )}
                      {showExercise.exerciseLevel.level ? (
                        <li>
                          <Dropdown className={"level-change-link"}>
                            <Dropdown.Toggle
                              variant="success"
                              id="dropdown-basic"
                            >
                              <svg
                                width="15"
                                height="25"
                                viewBox="0 0 15 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  x="0.853027"
                                  y="16.9424"
                                  width="2.45446"
                                  height="7.36337"
                                  fill="#9B9B9B"
                                />
                                <rect
                                  x="6.23291"
                                  y="8.96558"
                                  width="2.45446"
                                  height="15.3404"
                                  fill="#9B9B9B"
                                />
                                <rect
                                  x="11.6133"
                                  y="0.988525"
                                  width="2.45446"
                                  height="23.3173"
                                  fill="#9B9B9B"
                                />
                              </svg>
                              <h6>Level</h6>
                              <p
                                className={`level level${showExercise.exerciseLevel.level.id}`}
                              >
                                {" "}
                                {showExercise.exerciseLevel.level
                                  ? showExercise.exerciseLevel.level.name
                                  : ""}
                              </p>
                            </Dropdown.Toggle>

                            <Dropdown.Menu className={"level-change-link"}>
                              <h4 className="text-center">Exercise Levels</h4>
                              <div id="wrapper1">
                                <ul className="" id="chart01">
                                  <li>
                                    <span></span>{" "}
                                    <i>
                                      <b>Level 1</b> Easy{" "}
                                    </i>
                                  </li>
                                  <li>
                                    <span></span>{" "}
                                    <i>
                                      <b>Level 2</b> Moderate{" "}
                                    </i>
                                  </li>
                                  <li>
                                    <span></span>{" "}
                                    <i>
                                      <b>Level 3</b> Advanced{" "}
                                    </i>
                                  </li>
                                </ul>
                                {/* <img src={barchart} alt="barchart" /> */}
                                <div className="btn_chart text-center">
                                  <Link
                                    to={`/program/change-level`}
                                    state={{
                                      exercise: showExercise,
                                      activeDayId: activeDay,
                                      programId: program.id,
                                      redirectLink: "/assessment-timer",
                                    }}
                                    className="theme_btn1 small_btn"
                                  >
                                    Change Level
                                  </Link>
                                </div>
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        </li>
                      ) : (
                        ""
                      )}
                      {showExercise &&
                      showExercise.exerciseLevel &&
                      showExercise.exerciseLevel.exerciseEducationVideo ? (
                        <li>
                          <Link
                            // to="/images/ankle-scraping.mp4"
                            to="#"
                            className="edu popup-youtube-custom d-block"
                            onClick={(e) => {
                              e.preventDefault();
                              handlePause();
                              handleShow();
                            }}
                          >
                            <svg
                              width="28"
                              height="24"
                              viewBox="0 0 26 22"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M25.7266 5.55698C25.5718 5.86026 25.3072 6.00867 24.991 6.11837C21.2355 7.44118 17.48 8.7769 13.7309 10.1126C13.4664 10.2094 13.2276 10.203 12.9566 10.1126C9.1624 8.75755 5.36172 7.41537 1.56104 6.0732C1.28357 5.97641 1.1029 5.79573 0.960938 5.56343C0.960938 5.42147 0.960938 5.27305 0.960938 5.13109C1.01256 4.87298 1.18678 4.71812 1.41908 4.61487C1.76753 4.46001 2.12889 4.35676 2.49024 4.22771C5.16814 3.2856 7.83958 2.3435 10.5175 1.40785C11.3176 1.13038 12.1113 0.846462 12.9114 0.562541C13.2083 0.459296 13.4922 0.459296 13.7826 0.562541C16.0346 1.34978 18.2801 2.14347 20.5322 2.93716C21.9905 3.45338 23.4488 3.96314 24.9071 4.47936C25.0233 4.51808 25.133 4.5568 25.2491 4.60842C25.5072 4.72457 25.7073 4.87944 25.7331 5.18271C25.7266 5.30532 25.7266 5.43437 25.7266 5.55698Z"
                                fill="#0F02FF"
                              />
                              <path
                                d="M6.05886 10.8741C6.05886 10.4095 6.05886 9.93841 6.05886 9.47381C6.05886 9.20279 6.05886 9.2028 6.31051 9.28668C8.36249 10.0158 10.4145 10.7386 12.46 11.4742C13.0601 11.6871 13.6344 11.6871 14.2281 11.4742C16.2736 10.7386 18.332 10.0158 20.3775 9.28023C20.5647 9.20925 20.6356 9.2157 20.6292 9.448C20.6163 10.3707 20.6163 11.2999 20.6292 12.2227C20.6356 12.7131 20.4485 13.0938 20.0807 13.4035C19.4871 13.9004 18.7837 14.1714 18.0545 14.3844C16.0284 14.9909 13.9635 15.1458 11.8599 14.978C10.4338 14.8619 9.04003 14.6038 7.71076 14.0553C7.29133 13.881 6.89772 13.6681 6.55572 13.3648C6.22018 13.068 6.0395 12.7002 6.0524 12.242C6.06531 11.7904 6.05886 11.3322 6.05886 10.8741Z"
                                fill="#0F02FF"
                              />
                              <path
                                d="M23.9849 8.00903C23.9849 9.20279 23.9849 10.3707 23.9849 11.5387C23.9849 12.3969 23.9914 13.2616 23.9785 14.1198C23.9785 14.2618 24.0172 14.3456 24.1333 14.436C24.9141 15.0425 24.9077 16.1653 24.114 16.7525C23.9978 16.8429 23.9785 16.9332 23.9785 17.0558C23.9785 18.1657 23.9849 19.2756 23.9785 20.3854C23.9785 20.7274 23.772 21.0049 23.4687 21.1017C23.1719 21.192 22.8557 21.0888 22.675 20.8436C22.5653 20.6952 22.5266 20.5274 22.5266 20.3467C22.5266 19.2497 22.5266 18.1592 22.5331 17.0623C22.5331 16.9203 22.5008 16.8364 22.3782 16.7461C21.6168 16.1718 21.6039 15.0425 22.3588 14.4618C22.5008 14.3521 22.5331 14.2489 22.5331 14.0811C22.5266 12.313 22.5331 10.545 22.5266 8.77046C22.5266 8.57042 22.5911 8.49944 22.7718 8.44137C23.1654 8.31231 23.5591 8.1639 23.9849 8.00903Z"
                                fill="#0F02FF"
                              />
                            </svg>
                            <h6>Educational</h6>
                            <h6>Video</h6>
                          </Link>
                        </li>
                      ) : (
                        ""
                      )}
                    </ul>
                    <textarea
                      className="form-control form-2 text-left"
                      rows="5"
                      maxLength="500"
                      name="note"
                      id="note"
                      placeholder="Notes"
                      onChange={(e) => setNoteValueFunc(e)}
                    ></textarea>
                    {errorNote && (
                      <span className="show-error">{errorNote}</span>
                    )}
                  </div>
                )}
              </div>
              <div className="col-12 col-lg-6 col-md-6 test_one">
                <div className="box_test ex_box">
                  <div className="row">
                    <div className="col-9 col-md-7">
                      <h4 className="mb-20">
                        {showExercise.exerciseLevel
                          ? showExercise.exerciseLevel?.file_name
                          : ""}
                      </h4>
                    </div>
                    <div className="col-3 col-md-5 text-right">
                      <div className="btn-group">
                        <div className="icon_bar" onClick={handleClick}>
                          <svg
                            width="37"
                            height="37"
                            viewBox="0 0 37 37"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="18.1211"
                              cy="18.3081"
                              r="18"
                              fill="black"
                            ></circle>
                            <rect
                              x="10.9209"
                              y="18.6885"
                              width="3.32275"
                              height="9.53105"
                              fill="#1AE074"
                            ></rect>
                            <rect
                              x="15.9927"
                              y="13.5295"
                              width="3.32275"
                              height="14.6901"
                              fill="#9B9B9B"
                            ></rect>
                            <rect
                              x="21.4141"
                              y="8.10806"
                              width="3.32275"
                              height="20.1114"
                              fill="#9B9B9B"
                            ></rect>
                          </svg>
                        </div>
                        <div
                          className={
                            isActive
                              ? "dropdown-menu dropdown-menu-right bar_dropdown show"
                              : "dropdown-menu dropdown-menu-right bar_dropdown"
                          }
                        >
                          <h4>Exercise Levels</h4>
                          <ul className="chart01">
                            <li>
                              <span></span>{" "}
                              <i>
                                <b>Level 1</b> Easy{" "}
                              </i>
                            </li>
                            <li>
                              <span></span>{" "}
                              <i>
                                <b>Level 2</b> Moderate{" "}
                              </i>
                            </li>
                            <li>
                              <span></span>{" "}
                              <i>
                                <b>Level 3</b> Advanced{" "}
                              </i>
                            </li>
                          </ul>
                          <div id="wrapper1">
                            {/* <img src={barchart} alt="barchart" /> */}
                            <div className="btn_chart text-center">
                              <Link
                                to={`/program/change-level`}
                                state={{
                                  exercise: showExercise,
                                  activeDayId: activeDay,
                                  programId: program.id,
                                  redirectLink: "/assessment-timer",
                                }}
                                className="theme_btn1 small_btn"
                                onClick={() =>
                                  handleActiveExercise(showExercise)
                                }
                              >
                                Change Level
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {viewportWidth <= 480 && (
                    <div className="border-t position-relative">
                      {isStart &&
                        (showExercise.exerciseLevel.time &&
                        showExercise.exerciseLevel.metric == 20 ? (
                          <>
                            {!isTimerActive ? (
                              seconds == 0 ? (
                                <Button
                                  className="theme_btn1 mr-20"
                                  onClick={() => handleStart()}
                                  disabled={!isPaused || isCompleted}
                                >
                                  Start
                                </Button>
                              ) : (
                                <Button
                                  className="theme_btn_yellow mr-20"
                                  onClick={() => handleResume()}
                                  disabled={isCompleted}
                                >
                                  Resume
                                </Button>
                              )
                            ) : (
                              <Button
                                className="theme_btn_yellow mr-20"
                                onClick={() => handlePause()}
                                disabled={isCompleted}
                              >
                                Pause
                              </Button>
                            )}
                            {seconds !== 0 && (
                              <Button
                                className="theme_btn1 mr-20"
                                onClick={() => handleFinish()}
                                disabled={isCompleted}
                              >
                                Finish
                              </Button>
                            )}
                          </>
                        ) : (
                          <Button
                            className="theme_btn1 mr-20"
                            onClick={() => handleFinish()}
                          >
                            Done
                          </Button>
                        ))}

                      {/*{isStart === true ?*/}
                      {/*  <Button className="theme_btn1 mr-20" onClick={() => handleFinish()}*/}
                      {/*    disabled={isPaused || isCompleted}>*/}
                      {/*    Finish*/}
                      {/*  </Button> :*/}
                      {/*  <Button className="theme_btn1 mr-20" onClick={() => handleStart()}*/}
                      {/*    disabled={isPaused || isCompleted}>*/}
                      {/*    Start*/}
                      {/*  </Button>*/}
                      {/*}*/}

                      {/* <Button className="theme_btn1 mr-20" onClick={() => handleStartCountdown()}
                      disabled={isPaused || isCompleted}>
                      START timer
                    </Button>
                    <Link to="/assessment-timer2" className="theme_btn1 next">
                      NEXT
                    </Link> */}
                      {/*{nextExercise ?*/}
                      {/*    <Button className="theme_btn1 mr-20" disabled={isTimerActive} id={'nextExerciseButton'} onClick={() => setActiveExerciseFunc(nextExercise)}>*/}
                      {/*      Next*/}
                      {/*    </Button> :*/}
                      {/*    <Button className="theme_btn1 mr-20" disabled={isTimerActive} id={'completeExerciseButton'} onClick={() => handleCompleteExercise()}>*/}
                      {/*      Complete*/}
                      {/*    </Button>*/}
                      {/*}*/}
                      {!nextExercise && (
                        <Button
                          className="theme_btn1 mr-20"
                          disabled={isTimerActive || isCompleteDisable}
                          id={"completeExerciseButton"}
                          onClick={() => handleCompleteExercise()}
                        >
                          Complete
                        </Button>
                      )}
                      {isStart === false ? (
                        <Link
                          to="#"
                          className="theme-btn-link-black mb-1 float-none float-md-right mt-1"
                          onClick={() => {
                            setShowExerciseSkipModal(true);
                          }}
                        >
                          Skip Exercise
                        </Link>
                      ) : (
                        ""
                      )}
                      {showExercise.exerciseLevel.time &&
                      showExercise.exerciseLevel.metric == 20 ? (
                        <div className="timer">
                          {`${String(Math.floor(seconds / 60)).padStart(
                            2,
                            "0"
                          )}:${String(seconds % 60).padStart(2, "0")}`}
                        </div>
                      ) : (
                        ""
                      )}
                      {nextExercise ? (
                        ""
                      ) : (
                        <Link
                          to="#"
                          className="repeat-circuit-btn mb-1"
                          onClick={() => {
                            handleRepeatCircuit();
                          }}
                        >
                          Repeat Circuit
                        </Link>
                      )}
                    </div>
                  )}
                  {viewportWidth > 480 && (
                    <div>
                      {showExercise.exerciseLevel.starting_position && (
                        <>
                          <h6>Starting Position</h6>
                          <p>
                            <Interweave
                              content={
                                showExercise.exerciseLevel.starting_position
                              }
                            />
                          </p>
                        </>
                      )}

                      {showExercise.exerciseLevel.movements && (
                        <>
                          <h6>Movement</h6>
                          <p>
                            <Interweave
                              content={showExercise.exerciseLevel.movements}
                            />
                          </p>
                        </>
                      )}

                      {showExercise.exerciseLevel.notes && (
                        <div className="exc_p">
                          <p>
                            <span>Note:</span>
                            <Interweave
                              content={showExercise.exerciseLevel.notes}
                            />
                          </p>
                        </div>
                      )}
                    </div>
                  )}
                  {viewportWidth > 480 && (
                    <div className="border-t position-relative">
                      {isStart &&
                        (showExercise.exerciseLevel.time &&
                        showExercise.exerciseLevel.metric == 20 ? (
                          <>
                            {!isTimerActive ? (
                              seconds == 0 ? (
                                <Button
                                  className="theme_btn1 mr-20"
                                  onClick={() => handleStart()}
                                  disabled={!isPaused || isCompleted}
                                >
                                  {/* Start Working on */}
                                  Start
                                </Button>
                              ) : (
                                <Button
                                  className="theme_btn_yellow mr-20"
                                  onClick={() => handleResume()}
                                  disabled={isCompleted}
                                >
                                  Resume
                                </Button>
                              )
                            ) : (
                              <Button
                                className="theme_btn_yellow mr-20"
                                onClick={() => handlePause()}
                                disabled={isCompleted}
                              >
                                Pause
                              </Button>
                            )}
                            {seconds !== 0 && (
                              <Button
                                className="theme_btn1 mr-20 btn-finish"
                                onClick={() => handleFinish()}
                                disabled={isCompleted}
                              >
                                Finish
                              </Button>
                            )}
                          </>
                        ) : (
                          <Button
                            className="theme_btn1 mr-20"
                            onClick={() => handleFinish()}
                          >
                            Done
                          </Button>
                        ))}
                      {/*{isStart === true ?*/}
                      {/*  <Button className="theme_btn1 mr-20" onClick={() => handleFinish()}*/}
                      {/*    disabled={isPaused || isCompleted}>*/}
                      {/*    Finish*/}
                      {/*  </Button> :*/}
                      {/*  <Button className="theme_btn1 mr-20" onClick={() => handleStart()}*/}
                      {/*    disabled={isPaused || isCompleted}>*/}
                      {/*    Start*/}
                      {/*  </Button>*/}
                      {/*}*/}

                      {/* <Button className="theme_btn1 mr-20" onClick={() => handleStartCountdown()}
                      disabled={isPaused || isCompleted}>
                      START timer
                    </Button>
                    <Link to="/assessment-timer2" className="theme_btn1 next">
                      NEXT
                    </Link> */}
                      {/*{nextExercise ?*/}
                      {/*  <Button className="theme_btn1 mr-20" disabled={isTimerActive} id={'nextExerciseButton'} onClick={() => setActiveExerciseFunc(nextExercise)}>*/}
                      {/*    Next*/}
                      {/*  </Button> :*/}
                      {/*  <Button className="theme_btn1 mr-20" disabled={isTimerActive} id={'completeExerciseButton'} onClick={() => handleCompleteExercise()}>*/}
                      {/*    Complete*/}
                      {/*  </Button>*/}
                      {/*}*/}
                      {!nextExercise && (
                        <Button
                          className="theme_btn1 mr-20"
                          disabled={isTimerActive || isCompleteDisable}
                          id={"completeExerciseButton"}
                          onClick={() => handleCompleteExercise()}
                        >
                          Complete
                        </Button>
                      )}
                      {isStart && (
                        <Link
                          to="#"
                          className="theme-btn-link-black mb-1 float-none float-md-right mt-1"
                          onClick={() => {
                            setShowExerciseSkipModal(true);
                          }}
                        >
                          Skip Exercise
                        </Link>
                      )}
                      {showExercise.exerciseLevel.time &&
                      showExercise.exerciseLevel.metric == 20 ? (
                        <div className="timer">
                          {`${String(Math.floor(seconds / 60)).padStart(
                            2,
                            "0"
                          )}:${String(seconds % 60).padStart(2, "0")}`}
                        </div>
                      ) : (
                        ""
                      )}
                      {nextExercise ? (
                        ""
                      ) : (
                        <Link
                          to="#"
                          className="repeat-circuit-btn mb-1"
                          onClick={() => {
                            handleRepeatCircuit();
                          }}
                        >
                          Repeat Circuit
                        </Link>
                      )}
                    </div>
                  )}
                </div>

                {viewportWidth <= 480 &&
                  (showExercise.exerciseLevel.starting_position ||
                  showExercise.exerciseLevel.movements ||
                  showExercise.exerciseLevel.notes ? (
                    <div className="box_test ex_box">
                      <Accordion className="exercise-detail-accordion">
                        {showExercise.exerciseLevel.starting_position && (
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              <h6>Starting Position</h6>
                            </Accordion.Header>
                            <Accordion.Body>
                              <p>
                                <Interweave
                                  content={
                                    showExercise.exerciseLevel.starting_position
                                  }
                                />
                              </p>
                            </Accordion.Body>
                          </Accordion.Item>
                        )}

                        {showExercise.exerciseLevel.movements && (
                          <Accordion.Item eventKey="1">
                            <Accordion.Header>
                              {" "}
                              <h6>Movement</h6>
                            </Accordion.Header>
                            <Accordion.Body>
                              <p>
                                <Interweave
                                  content={showExercise.exerciseLevel.movements}
                                />
                              </p>
                            </Accordion.Body>
                          </Accordion.Item>
                        )}

                        {showExercise.exerciseLevel.notes && (
                          <Accordion.Item eventKey="2">
                            <Accordion.Header>
                              {" "}
                              <h6>Notes</h6>
                            </Accordion.Header>
                            <Accordion.Body>
                              <p style={{ color: "red" }}>
                                <Interweave
                                  content={showExercise.exerciseLevel.notes}
                                />
                              </p>
                            </Accordion.Body>
                          </Accordion.Item>
                        )}
                      </Accordion>
                    </div>
                  ) : null)}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <ExerciseEducationalVideoModal
          show={showEducationalModal}
          handleClose={handleClose}
          videos={
            showExercise
              ? showExercise.exerciseLevel
                ? showExercise.exerciseLevel.exerciseEducationVideo
                : null
              : null
          }
        />
        <SkipExerciseModal
          show={showExerciseSkipModal}
          handleClose={handleCloseExerciseSkipModal}
          skipTestReasons={skipTestReason ? skipTestReason : null}
          exerciseId={showExercise ? showExercise.id : null}
          dayId={showExercise ? showExercise.user_program_day_id : null}
        />
        <StatusCheckoutModal
          show={showStatusOutModal}
          handleClose={handleStatusOutModal}
          dayId={showExercise ? showExercise.user_program_day_id : null}
        />
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    exerciseUpdateRequest: state.programReducer.exerciseUpdateRequest || null,
    exerciseUpdateSuccess: state.programReducer.exerciseUpdateSuccess || null,
    exerciseUpdateFailure: state.programReducer.exerciseUpdateFailure || null,
    skipExerciseSuccess: state.testReducer.skipExerciseSuccess,
    skipTestReason: state.testReducer.skipTestReason,
  };
};
export default connect(mapStateToProps, {
  updateUserProgramDayExercise,
  getSkipReasons,
})(Test1Week1);
