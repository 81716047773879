import React, { useEffect, useState } from "react";
import WeekSlider from "./WeekSlider";
import Header from "../../Layout/Header";
import Banner from "./Banner";
import BreadcrumbMenu from "./BreadcrumbMenu";
import Exercises from "./Exercises";
import Footer from "../../Layout/Footer";
import PropTypes from "prop-types";
import { connect, useStore } from "react-redux";
import Exercise from "./Exercise";
import ScrollTopOnMount from "../../Layout/ScrollTopOnMount";

const Index = () => {
  const [start, setStart] = useState(false);
  return (
    <>
      <ScrollTopOnMount />
      <Header />
      <Banner setStart={setStart} />
      <WeekSlider setStart={setStart} start={start} />
      <Footer />
    </>
  );
};

// Index.propTypes = {
//     getWeekSchedule : PropTypes.func.isRequired,
//
// };

// const mapStateToProps = state => ({
// })
export default connect(null, {})(Index);
