import React, { Navigate, useEffect } from "react";
import Footer from "../../Layout/Footer";
import Header from "../../Layout/Header";
import ScrollTopOnMount from "../../Layout/ScrollTopOnMount";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { getPageBySlug } from "../../../actions/auth";
import { Interweave } from "interweave";

const PrivatePages = ({ getPageBySlug, page }) => {
  let { slug } = useParams();
  useEffect(() => {
    getPageBySlug(slug);
  }, [slug]);
  return (
    <>
      <ScrollTopOnMount />
      <Header />
      <div className={"clearfix"}></div>
      <section className={"recomended-products"}>
        <Container>
          <Row>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
              <div className="videos_headings">
                <h2 className="detail_span" style={{ color: "#fff" }}>
                  {page ? page.name : ""}
                </h2>
              </div>
            </div>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className={"mx-auto"}>
              <div className={"mx-auto page-title page-title-terms"}>
                {/* {page?.content} */}
                <Interweave
                  content={page ? page.content : "No Data Available"}
                  noHtml={false}
                  allowList={[
                    "iframe",
                    "img",
                    "video",
                    "p",
                    "a",
                    "div",
                    "h1",
                    "h2",
                    "h3",
                    "h4",
                    "h5",
                    "h6",
                  ]}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    page: state.auth.page,
  };
};
export default connect(mapStateToProps, { getPageBySlug })(PrivatePages);
