import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import { getUserProfile } from "../../../actions/auth";
import { Container, Row, Col } from "react-bootstrap";

const ProgramList = () => {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      const parsedUser = JSON.parse(user);
      console.log("user", parsedUser);
      setUserData(parsedUser);
    }
  }, []);

  useEffect(() => {
    if (userData) {
      console.log("userData", userData);
    }
  }, [userData]);

  return (
    <>
      <Header />
      <section className="assestmentd1_001 program-box-height">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12 col-md-12 text-center">
              <h1>My Programs</h1>
              <>
                <div className="programs">
                  {userData?.userActivePrograms?.length > 0 ? (
                    userData.userActivePrograms.map(
                      (data, key) =>
                        data && (
                          // <div key={key}>{data.program.name}</div>
                          <div
                            key={key}
                            className={`program-card ${
                              data.is_default == 1 ? "active" : ""
                            }`}
                          >
                            <Link to={`/program/${data.program_id}`}>
                              {data.program.name}
                            </Link>
                          </div>
                          // <div
                          //   className="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2 common-box-height"
                          //   key={key}
                          // >
                          //   <Link
                          //     className="nav-link library-nav"
                          //     data-toggle="tab"
                          //     to={`/program/${data.program_id}`}
                          //     role="tab"
                          //   >
                          //     <div className="library-img-container">
                          //       <div className="library-img-container-inner">
                          //         <div className="title-one">
                          //           {data.program.name}
                          //         </div>
                          //       </div>
                          //     </div>
                          //   </Link>
                          // </div>
                        )
                    )
                  ) : (
                    <p>No active programs available.</p>
                  )}
                </div>
              </>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ProgramList;
