import React, { useEffect, useState } from "react";
import ScrollTopOnMount from "../../Layout/ScrollTopOnMount";
import { Link, useNavigate } from "react-router-dom";
import Header from "../Home/Header";
import Header2 from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { getPainCategories, getPainPoint } from "../../../actions/user_program";
import { connect } from "react-redux";

import front_side_1 from "../../images/front_side/1.jpg";
import front_side_2 from "../../images/front_side/2.jpg";
import front_side_3 from "../../images/front_side/3.jpg";
import front_side_4 from "../../images/front_side/4.jpg";
import front_side_5 from "../../images/front_side/5.jpg";
import front_side_6 from "../../images/front_side/6.jpg";
import front_side_7 from "../../images/front_side/7.jpg";
import front_side_8 from "../../images/front_side/8.jpg";
import front_side_9 from "../../images/front_side/9.jpg";
import front_side_10 from "../../images/front_side/10.jpg";
import front_side_11 from "../../images/front_side/11.jpg";

import back_side_1 from "../../images/back_side/1.jpg";
import back_side_2 from "../../images/back_side/2.jpg";
import back_side_3 from "../../images/back_side/3.jpg";
import back_side_4 from "../../images/back_side/4.jpg";
import back_side_5 from "../../images/back_side/5.jpg";
import back_side_6 from "../../images/back_side/6.jpg";
import back_side_7 from "../../images/back_side/7.jpg";
import back_side_8 from "../../images/back_side/8.jpg";
import back_side_9 from "../../images/back_side/9.jpg";
import back_side_10 from "../../images/back_side/10.jpg";
import back_side_11 from "../../images/back_side/11.jpg";

import FadeLoader from "react-spinners/FadeLoader";
import { cross_origin } from "../../../utils/constants";

const TriggerCategory = ({
  getPainCategories,
  painCategories,
  getPainPoint,
  pain_point,
}) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(false);
  const [loader, setLoader] = useState(true);
  const [musclesAnatomyData, setMusclesAnatomyData] = useState([]);

  const [painPointData, setPainPointData] = useState(null);

  const [currentPage, setCurrentPage] = useState(
    pain_point ? parseInt(pain_point.meta.current_page) : 1
  );
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [totalPages, setTotalPages] = useState(0);
  const [keyword, setKeyword] = useState(null);

  useEffect(() => {
    getPainPoint(currentPage, itemsPerPage, 1, keyword);
  }, [currentPage, itemsPerPage, keyword]);

  useEffect(() => {
    setTotalPages(pain_point ? parseInt(pain_point.meta.last_page) : 1);
    if (pain_point?.data) {
      setLoader(false);
    }
    setPainPointData(pain_point ? pain_point.data : []);
    // setPainPointData(array)
  }, [pain_point]);

  useEffect(() => {
    setTimeout(() => {
      const topElement = document.querySelector(".trigger-banner-recommended"); // Replace 'top-element' with the actual ID of your top element
      if (topElement) {
        topElement.scrollIntoView({
          behavior: "smooth",
          block: "start", // Scroll to the top of the element
        });
      }
    }, 2000);
  }, []);

  const handleItemsPerPageChange = (e) => {
    setLoader(true);
    setItemsPerPage(parseInt(e.target.value));
  };

  const handleSearchClick = () => {
    getPainPoint(currentPage, itemsPerPage, 1, keyword);
    setLoader(true);
    setCurrentPage(1);
  };
  let serchKey = "";
  const handleSearch = (value) => {
    console.log("value-->", value);
    console.log("keyword-->>", keyword);
    serchKey = value;
    console.log("serchKey-->", serchKey);

    // setVal(true);
    setKeyword(value);
    if (keyword && value.length == 0) {
      getPainPoint(currentPage, itemsPerPage, 1, value);
    }
  };

  useEffect(() => {
    getPainCategories();
  }, []);

  useEffect(() => {
    if (painCategories) {
      setLoader(false);
    }
    setMusclesAnatomyData(painCategories ? painCategories : []);
  }, [painCategories]);

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setUser(true);
    } else {
      setUser(false);
    }
  }, []);

  const [frontBodyPartValue, setFrontBodyPartValue] = useState(front_side_1);
  const [backBodyPartValue, setBackBodyPartValue] = useState(back_side_1);

  const handleMouseEnter = (frontArea, backArea) => {
    setFrontBodyPartValue(frontArea);
    setBackBodyPartValue(backArea);
  };

  const handleMouseLeave = () => {
    setFrontBodyPartValue(front_side_1);
    setBackBodyPartValue(back_side_1);
  };

  const onClickBodyParts = (item) => {
    if (musclesAnatomyData.length > 0) {
      musclesAnatomyData.map((itemData) => {
        if (itemData.name == item) {
          navigate(`/trigger-categories/${itemData.id}`, {
            state: { pain_point_data: itemData },
          });
        }
      });
    }
  };

  const frontBodyPart = () => {
    return (
      <div style={{ position: "relative", width: "350px" }}>
        <img src={frontBodyPartValue} alt="Body" style={{ width: "100%" }} />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Head and Neck Front")}
          style={{
            top: "3%",
            left: "39%",
            width: "21%",
            height: "14%",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_2, back_side_1)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Head and Neck Front")}
          style={{
            top: "17%",
            left: "32%",
            width: "36%",
            height: "3%",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_2, back_side_1)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Shoulder and Arm Front")}
          style={{
            top: "18%",
            left: "19%",
            width: "13%",
            height: "16%",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_3, back_side_1)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Shoulder and Arm Front")}
          style={{
            top: "18%",
            right: "19%",
            width: "13%",
            height: "16%",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_3, back_side_1)}
          onMouseLeave={handleMouseLeave}
        />

        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Elbow Front")}
          style={{
            top: "34%",
            left: "17%",
            width: "11%",
            height: "3.2%",
            transform: "rotate(6deg)",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_4, back_side_1)}
          onMouseLeave={handleMouseLeave}
        />

        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Elbow Front")}
          style={{
            top: "34%",
            right: "19%",
            width: "11%",
            height: "3.2%",
            transform: "rotate(-6deg)",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_4, back_side_1)}
          onMouseLeave={handleMouseLeave}
        />

        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Forearm wrist and hand front")}
          style={{
            top: "37%",
            left: "15%",
            width: "12%",
            height: "11%",
            transform: "rotate(6deg)",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_5, back_side_1)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Forearm wrist and hand front")}
          style={{
            top: "47.5%",
            left: "8.5%",
            width: "14.5%",
            height: "10.5%",
            transform: "rotate(6deg)",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_5, back_side_1)}
          onMouseLeave={handleMouseLeave}
        />

        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Forearm wrist and hand front")}
          style={{
            top: "47.5%",
            right: "6.5%",
            width: "14.5%",
            height: "10.5%",
            transform: "rotate(-6deg)",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_5, back_side_1)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Forearm wrist and hand front")}
          style={{
            top: "37%",
            right: "15%",
            width: "12%",
            height: "11%",
            transform: "rotate(-8deg)",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_5, back_side_1)}
          onMouseLeave={handleMouseLeave}
        />

        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Chest")}
          style={{
            top: "20%",
            left: "32%",
            width: "35%",
            height: "9%",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_6, back_side_1)}
          onMouseLeave={handleMouseLeave}
        />

        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Abdomen")}
          style={{
            top: "29%",
            left: "32%",
            width: "35%",
            height: "13%",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_7, back_side_1)}
          onMouseLeave={handleMouseLeave}
        />

        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Pelvis Front")}
          style={{
            top: "42%",
            left: "32%",
            width: "36%",
            height: "10%",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_8, back_side_1)}
          onMouseLeave={handleMouseLeave}
        />

        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Upper Leg Front")}
          style={{
            top: "52%",
            left: "30%",
            width: "19%",
            height: "10%",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_9, back_side_1)}
          onMouseLeave={handleMouseLeave}
        />

        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Upper Leg Front")}
          style={{
            top: "52%",
            right: "31%",
            width: "19%",
            height: "10%",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_9, back_side_1)}
          onMouseLeave={handleMouseLeave}
        />

        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Upper Leg Front")}
          style={{
            top: "62%",
            left: "32%",
            width: "17%",
            height: "6.5%",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_9, back_side_1)}
          onMouseLeave={handleMouseLeave}
        />

        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Upper Leg Front")}
          style={{
            top: "62%",
            right: "33%",
            width: "17%",
            height: "6.5%",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_9, back_side_1)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Knees Front")}
          style={{
            top: "68.5%",
            left: "35%",
            width: "13%",
            height: "6.5%",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_10, back_side_1)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Knees Front")}
          style={{
            top: "68.5%",
            right: "36%",
            width: "13%",
            height: "6.5%",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_10, back_side_1)}
          onMouseLeave={handleMouseLeave}
        />

        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Lower Legs and Feet front")}
          style={{
            top: "75%",
            left: "35%",
            width: "13%",
            height: "12%",
            transform: "rotate(-3deg)",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_11, back_side_1)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Lower Legs and Feet front")}
          style={{
            top: "75%",
            right: "36%",
            width: "13%",
            height: "12%",
            transform: "rotate(3deg)",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_11, back_side_1)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Lower Legs and Feet front")}
          style={{
            top: "86.8%",
            left: "39%",
            width: "8%",
            height: "7%",
            transform: "rotate(-3deg)",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_11, back_side_1)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Lower Legs and Feet front")}
          style={{
            top: "86.8%",
            right: "40%",
            width: "8%",
            height: "7%",
            transform: "rotate(3deg)",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_11, back_side_1)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Lower Legs and Feet front")}
          style={{
            top: "93.6%",
            left: "36%",
            width: "11%",
            height: "4%",
            transform: "rotate(-3deg)",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_11, back_side_1)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Lower Legs and Feet front")}
          style={{
            top: "93.6%",
            right: "37%",
            width: "11%",
            height: "4%",
            transform: "rotate(3deg)",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_11, back_side_1)}
          onMouseLeave={handleMouseLeave}
        />
      </div>
    );
  };

  const backBodyPart = () => {
    return (
      <div
        style={{
          position: "relative",
          width: "350px",
          borderWidth: 1,
          borderColor: "black",
        }}
      >
        <img src={backBodyPartValue} alt="backBody" style={{ width: "100%" }} />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Head and Neck Back")}
          style={{
            top: "2%",
            left: "38.7%",
            width: "21.1%",
            height: "15%",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_1, back_side_2)}
          onMouseLeave={handleMouseLeave}
        />
        {/* <div
          style={{
            position: "absolute",
            top: "17%",
            left: "32%",
            width: "36%",
            height: "3%",
            cursor: "pointer",
            backgroundColor: "rgba(255, 255, 0, 0.6)",
          }}
          onMouseEnter={() => handleMouseEnter(back_side_2)}
          onMouseLeave={handleMouseLeave}
        /> */}
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Shoulder and Arm Back")}
          style={{
            top: "18%",
            left: "19%",
            width: "13%",
            height: "16%",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_1, back_side_3)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Shoulder and Arm Back")}
          style={{
            top: "18%",
            right: "19%",
            width: "13%",
            height: "16%",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_1, back_side_3)}
          onMouseLeave={handleMouseLeave}
        />

        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Elbows Back")}
          style={{
            top: "34%",
            left: "17%",
            width: "11%",
            height: "3.2%",
            transform: "rotate(6deg)",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_1, back_side_4)}
          onMouseLeave={handleMouseLeave}
        />

        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Elbows Back")}
          style={{
            position: "absolute",
            top: "34%",
            right: "19%",
            width: "11%",
            height: "3.2%",
            cursor: "pointer",
            transform: "rotate(-6deg)",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_1, back_side_4)}
          onMouseLeave={handleMouseLeave}
        />

        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Forearm wrist and hand back")}
          style={{
            top: "37%",
            left: "13%",
            width: "12%",
            height: "11%",
            transform: "rotate(6deg)",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_1, back_side_5)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Forearm wrist and hand back")}
          style={{
            top: "37%",
            right: "15%",
            width: "12%",
            height: "11%",
            transform: "rotate(-8deg)",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_1, back_side_5)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Forearm wrist and hand back")}
          style={{
            top: "47.5%",
            left: "5.5%",
            width: "14.5%",
            height: "10.5%",
            transform: "rotate(6deg)",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_1, back_side_5)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Forearm wrist and hand back")}
          style={{
            top: "47.5%",
            right: "8.5%",
            width: "14.5%",
            height: "10.5%",
            transform: "rotate(-6deg)",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_1, back_side_5)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Upper Back")}
          style={{
            top: "15%",
            left: "38%",
            width: "22%",
            height: "1.5%",
            borderTopLeftRadius: "70%",
            borderTopRightRadius: "70%",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_1, back_side_6)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Upper Back")}
          style={{
            top: "16.5%",
            left: "30%",
            width: "38%",
            height: "15.5%",
            borderTopLeftRadius: "25%",
            borderTopRightRadius: "25%",
            borderBottomLeftRadius: "16%",
            borderBottomRightRadius: "16%",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_1, back_side_6)}
          onMouseLeave={handleMouseLeave}
        />

        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Lower Back")}
          style={{
            top: "31%",
            left: "32%",
            width: "35%",
            height: "11%",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_1, back_side_7)}
          onMouseLeave={handleMouseLeave}
        />

        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Pelvis Back")}
          style={{
            top: "42%",
            left: "32%",
            width: "36%",
            height: "9.5%",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_1, back_side_8)}
          onMouseLeave={handleMouseLeave}
        />

        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Upper Leg Back")}
          style={{
            top: "51.5%",
            left: "30%",
            width: "19%",
            height: "10%",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_1, back_side_9)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Upper Leg Back")}
          style={{
            top: "51.5%",
            right: "31%",
            width: "19%",
            height: "10%",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_1, back_side_9)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Upper Leg Back")}
          style={{
            top: "61.5%",
            left: "32%",
            width: "17%",
            height: "8%",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_1, back_side_9)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Upper Leg Back")}
          style={{
            top: "61.5%",
            right: "33%",
            width: "17%",
            height: "8%",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_1, back_side_9)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Knees Back")}
          style={{
            top: "69.5%",
            left: "35%",
            width: "13%",
            height: "5.5%",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_1, back_side_10)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Knees Back")}
          style={{
            top: "69.5%",
            right: "36%",
            width: "13%",
            height: "5.5%",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_1, back_side_10)}
          onMouseLeave={handleMouseLeave}
        />

        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Lower Legs and feet back")}
          style={{
            top: "75%",
            left: "35%",
            width: "13%",
            height: "12%",
            transform: "rotate(-3deg)",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_1, back_side_11)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Lower Legs and feet back")}
          style={{
            top: "75%",
            right: "36%",
            width: "13%",
            height: "12%",
            transform: "rotate(3deg)",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_1, back_side_11)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Lower Legs and feet back")}
          style={{
            top: "86.8%",
            left: "39%",
            width: "8%",
            height: "7%",
            transform: "rotate(-3deg)",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_1, back_side_11)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Lower Legs and feet back")}
          style={{
            top: "86.8%",
            right: "40%",
            width: "8%",
            height: "7%",
            transform: "rotate(3deg)",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_1, back_side_11)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Lower Legs and feet back")}
          style={{
            top: "93.6%",
            left: "36%",
            width: "11%",
            height: "2.5%",
            transform: "rotate(-3deg)",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_1, back_side_11)}
          onMouseLeave={handleMouseLeave}
        />
        <button
          className="transbtn"
          onClick={() => onClickBodyParts("Lower Legs and feet back")}
          style={{
            top: "93.6%",
            right: "37%",
            width: "11%",
            height: "2.5%",
            transform: "rotate(3deg)",
          }}
          onMouseEnter={() => handleMouseEnter(front_side_1, back_side_11)}
          onMouseLeave={handleMouseLeave}
        />
      </div>
    );
  };

  return (
    <div>
      <ScrollTopOnMount />
      {user ? <Header2 /> : <Header />}
      <div className={"clearfix"}></div>

      <section className={"recomended-products"}>
        <Container>
          <Row>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
              <div className="videos_headings">
                <h2 className="detail_span" style={{ color: "#fff" }}>
                  TRIGGER POINT LIBRARY
                </h2>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <Form
                className="d-flex mt-3"
                onSubmit={(e) => e.preventDefault()}
              >
                <Form.Control
                  value={keyword}
                  type="search"
                  placeholder="Search"
                  className="me-2 rounded-pill"
                  aria-label="Search"
                  onChange={(e) => {
                    handleSearch(e.target.value);
                    handleSearchClick(e.target.value);
                  }}
                />
              </Form>
            </div>

            {keyword ? (
              <>
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                  <div
                    className={
                      "Educational-info d-flex flex-column text-start mb-3"
                    }
                  >
                    <h3 className={"text-center"}>
                      <p>
                        Search by pain area (i.e. knee, hip, low back, etc.)
                      </p>
                    </h3>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                  <hr />
                  <div className="row">
                    {loader ? (
                      <div className="col-12 col-sm-12 col-md-12  col-lg-12 col-xl-12 ">
                        <div className={"empty-page"}>
                          <div className="spin-loader">
                            <FadeLoader color={"#EC1246"} height={10} />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        {painPointData?.length > 0 ? (
                          painPointData.map((data, key) => {
                            if (data) {
                              return (
                                <div
                                  className="col-6 col-sm-6 col-md-3  col-lg-2 col-xl-2 common-box-height"
                                  key={key}
                                >
                                  <Link
                                    className={"nav-link library-nav"}
                                    data-toggle="tab"
                                    to={
                                      "/trigger-pain-point/detail/" + data?.id
                                    }
                                    role="tab"
                                  >
                                    <div className="library-img-container">
                                      <div className="library-img-container-inner">
                                        <div className="title-one">
                                          {data?.title}
                                        </div>
                                        <div className="title-two">
                                          {data?.title2}
                                        </div>
                                        <div />
                                      </div>
                                      <div className="library-img-box">
                                        <img
                                          src={data?.image ? data?.image : ""}
                                          alt={data?.title ? data?.title : ""}
                                          crossOrigin={
                                            cross_origin ? "anonymous" : null
                                          }
                                        />
                                      </div>
                                    </div>
                                  </Link>
                                </div>
                              );
                            }
                          })
                        ) : (
                          <div className="col-12 col-sm-12 col-md-12  col-lg-12 col-xl-12 ">
                            <div className={"library-empty-page"}>
                              <span className="itemsPerPagelabel">
                                Not Found
                              </span>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
                {painPointData?.length > 0 && (
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
                    <div className="pagination">
                      <span className="itemsPerPagelabel">Items Per Page:</span>
                      <div className={"perpagedropdown"}>
                        <select
                          id="itemsPerPage"
                          value={itemsPerPage}
                          onChange={handleItemsPerPageChange}
                        >
                          <option value={4}>4</option>
                          <option value={8}>8</option>
                          <option value={12}>12</option>
                          <option value={16}>16</option>
                          <option value={20}>20</option>
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path d="M7 10l5 5 5-5z"></path>
                          <path d="M0 0h24v24H0z" fill="none"></path>
                        </svg>
                      </div>
                      <span className="totalpages">
                        {currentPage}-{totalPages} of {totalPages}
                      </span>
                      <div className="paginationbuttons">
                        {currentPage > 1 ? (
                          <button
                            className={"button-previous"}
                            onClick={() => {
                              setCurrentPage(currentPage - 1);
                              setLoader(true);
                            }}
                            disabled={currentPage === 1}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              aria-hidden="true"
                              role="presentation"
                            >
                              <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                              <path d="M0 0h24v24H0z" fill="none"></path>
                            </svg>
                          </button>
                        ) : (
                          ""
                        )}
                        {currentPage < totalPages ? (
                          <button
                            className={"button-next"}
                            onClick={() => {
                              setCurrentPage(currentPage + 1);
                              setLoader(true);
                            }}
                            disabled={currentPage === totalPages}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              aria-hidden="true"
                              role="presentation"
                            >
                              <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                              <path d="M0 0h24v24H0z" fill="none"></path>
                            </svg>
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="human-body-container">
                {frontBodyPart()}
                {backBodyPart()}
              </div>
            )}
          </Row>
        </Container>
      </section>
      <Footer />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    pain_point: state.programReducer.pain_point,
    painCategories: state.programReducer.musclesCategories,
  };
};
export default connect(mapStateToProps, { getPainCategories, getPainPoint })(
  TriggerCategory
);
